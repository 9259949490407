.gallery {
	@extend .row;
}

	.gallery__listing {
		@extend %list-reset;
		@include flex-container();	
	}

	.gallery__item {
		@extend %list-item-reset;
		@include grid-col(1,2);
		margin-bottom: 15px;

		@include mq(xs) {
			@include grid-col(1,3);
		}

		@include mq(sm) {
			@include grid-col(1,2);
		}

		@include mq(md) {
			@include grid-col(1,3);
		}
	}

	.gallery__item-image {
		img {
			@include transition(all ease-in-out 300ms);
			max-width: 100%;
			height: auto;

			.gallery__item:hover & {
				@include scale(1.1);
			}	
		}
	}