.grid {
	
}

	.grid__listing {
		@extend %list-reset;
		@include flex-container();
	}

	.grid__item {
		@include grid-col(1,1);
		@include transition(all ease-in-out 300ms);
		background-color: $white;
		border: 1px solid $almost-white;
		cursor: pointer;
		padding: 15px;
		
		@include mq(sm) {
			@include grid-col(1,2);
		}

		@include mq(md) { 
			@include grid-col(1,3);
		}

		&:hover {
			background-color: $yellow;
			border-color: $yellow;
			color: $white;
		}

		a {
			text-decoration: none;
		}
	}


	.grid__headline {
		@include transition(color ease-in-out 300ms);
		font-family: font(condensed);
		font-size: font(size-l);
		text-transform: none;

		.grid__item:hover & {
			color: $white;
		}		
	}


	.grid__image-box {
		margin-bottom: 10px;
		overflow: hidden;

		img {
			@include transition(all ease-in-out 300ms);
			max-width: 100%;
			width: 100%;

			.grid__item:hover & {
				@include scale(1.1);
			}	
		}
	}

	.grid__content-box {
		@include transition(color ease-in-out 300ms);
		font-family: font(light);
		font-size: font(size-sm);
		padding: 0;

		.grid__item:hover & {
			color: $white;
		}	
	}