/*  -------------------------------------------------------------
* 	=Helpers - reusable classes
*	------------------------------------------------------------- */

	.ir {
		background-color: transparent;
		border: 0;
		overflow: hidden;
		*text-indent: -9999px;
	}

	.ir:before {
		content: "";
		display: block;
		height: 150%;
		width: 0;
	}



	.container {
		@include grid-container(map-get($outer-grid, gutter), true);
	}

	.content-container {
		@extend .container;
		max-width: map-get($guards, lg) !important;
	}

	.content-container--small {
		@extend .container;
		max-width: 800px !important;
	}

	.content-container--medium {
		@extend .container;
		max-width: 1000px !important;
	}

	.flex-container {
		@include flex-container;
	}

	.row {
		@include grid-row;
	}

	.flex-row {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	.clearfix {
		@extend %clearfix;
	}

	.hide-on-print {
		@extend %hide-on-print;
	}

	.no-wrap {
		@extend %no-wrap;
	}

	.visuallyhidden {
		@extend %visuallyhidden;
	}

	.fixed-element {
		position: fixed;
	}

	.center-align{
		text-align: center;
	}

	.right-align{
		text-align: right;
	}

	// Basic fade on background and colour
	%transition-links {
		@include transition(
			color 0.3s ease,
			background-color 0.3s ease
		);
	}

	// Transition everything
	%transition-fade {
		@include transition(all 0.3s ease);
	}

	// Placeholder for removing list styles
	%list-reset {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	// Placeholder for removing list styles
	%list-item-reset {
		padding: 0;
		margin: 0;
		position: static;

		&:before{
			content: '';
			display: none;
		}
	}

	// Placeholder for removing definition list styles
	%definition-reset {
		dd {
			margin: 0;
		}
	}

	%hide-select-arrow {
		-webkit-appearance: none;
		-moz-appearance: none;
		text-indent: 1px;
		text-overflow: '';

		&::-ms-expand {
			display: none;
		}
	}

.is-centered{
	text-align: center;
}

.is-hidden{
	display: none !important;
}

.content__main-inner--no-min-height{
	min-height: 0;
}

.content__main--404{
	display: flex;
	justify-content: center;
}

.has-font-light {
	font-weight: 300;
}

.is-text-center {
	text-align: center;
}

.w-48 {
	width: 12rem;
}
