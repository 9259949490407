
$item-pad-xs: map-get($outer-grid, gutter) / 2;
$item-pad-sm: 15px;
$item-pad-md: 15px;
$item-pad-lg: 20px;
$item-pad-xlg: 25px;

$item-img-width-xs: 160px;
$item-img-width-sm: 100px;
$item-img-width-md: 120px;
$item-img-width-lg: 140px;
$item-img-width-xlg: 200px;

.listing-news {
	@extend %list-reset;

	@include mq(sm) {
		max-height: 450px;
	}

	@include mq(md) {
		max-height: 500px;
	}

	@include mq(lg) {
		max-height: 600px;
	}

	@include mq(xlg) {
		max-width: 620px;
		float: right;
		width: 100%;
		max-height: 800px;
	}

	// Override the scrollbar color
	.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
	.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
	.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, 
	.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
		background-color: $yellow;
	}
}

	.listing-news__item {
		border-top: 2px solid $almost-black;
	}
		.listing-news__item-clickable {
			@extend %clearfix;
			text-decoration: none;
			position: relative;
			display: block;
			cursor: pointer;

			.listing-news__item.active &,
			.listing-news__item:hover &,
			&:hover {
				background: $yellow; 
				color: $black;

				&:after {
					opacity: 1;
				}
			}

			// Triangle
			&:after {
				@include translate(0, -50%);
				@include transition(opacity 0.3s ease);
				display: block;
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 30px 30px 30px 0;
				border-color: transparent $black transparent transparent;
				position: absolute;
				right: 0;
				top: 50%;
				opacity: 0;
			}
		}
			.listing-news__item-img {
				display: none;
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				background: none center center no-repeat;
				background-size: cover;
				
				@include mq(xs){
					display: block;
					width: $item-img-width-xs;
				}
				@include mq(sm){
					// display: none;
					width: $item-img-width-sm;
				}
				@include mq(md){
					display: block;
					width: $item-img-width-md;
				}
				@include mq(lg){
					width: $item-img-width-lg;
				}
				@include mq(xlg){
					width: $item-img-width-xlg;
				}
			}

			.listing-news__item-details {
				padding: $item-pad-xs;
				font-size: font(size-xxs);
				
				@include mq(xs){
					margin-left: $item-img-width-xs;
					padding: $item-pad-xs;
					font-size: font(size-xxs);
				}
				@include mq(sm){
					margin-left: $item-img-width-sm;
					padding: $item-pad-sm;
					font-size: font(size-xxxs);
				}
				@include mq(md){
					margin-left: $item-img-width-md;
					padding: $item-pad-md;
					font-size: font(size-xxs);
				}
				@include mq(lg){
					margin-left: $item-img-width-lg;
					padding: $item-pad-lg;
					font-size: font(size-sm);
				}
				@include mq(xlg){
					margin-left: $item-img-width-xlg;
					padding: $item-pad-xlg;
					font-size: font(size-sm);
				}

				&:after {
					display: block;
					content: 'Read More';
					text-transform: uppercase;
					margin-top: 20px;
					font-family: font(condensed);
					font-size: font(size-sm);
					line-height: 1;
					color: $yellow;

					@include mq(lg){
						font-size: font(size-m);
					}

					.listing-news__item.active &,
					.listing-news__item:hover & {
						color: palette(primary-text);
					}
				}
			}
				.listing-news__item-title {
					line-height: 1.14;
					font-size: font(size-m);

					@include mq(xs){
						height: 80px;
						overflow: hidden;
					}

					@include mq(sm){
						height: 67px;
						font-size: font(size-xs);
					}
					@include mq(md){
						height: 80px;
						font-size: font(size-m);
					}
					@include mq(lg){
						height: 100px;
						font-size: font(size-l);
					}
					@include mq(xlg){
						height: 125px;
						font-size: font(size-xl);
					}
				}
				.listing-news__item-date {
					display: block;
					margin-top: 20px;
					font-family: font(light);
					font-size: font(size-xxxs);
					line-height: 1;
					color: $dark-grey;

					@include mq(lg){
						font-size: font(size-xxs);
					}
				}

		.listing-news__content {
			background: palette(primary-background);
			color: palette(primary-text);
			display: none;
			padding: $item-pad-xs;

			@include mq(sm){
				padding: $item-pad-sm;
				display: none !important;
			}
			@include mq(md){
				padding: $item-pad-md;
			}
			@include mq(lg){
				padding: $item-pad-lg;
			}
			@include mq(xlg){
				padding: $item-pad-xlg;
			}

			p:last-of-type {
				margin-bottom: 0;
			}

			.listing-news__item.active & {
				display: block;
			}
		}