// -----------------------------------------------------------------------
// =Add any icon classes to this file, prefixed with icon-
// -----------------------------------------------------------------------

[class^="icon-"], [class*=" icon-"],%icons {
	font-family: 'icon-fonts';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-menu {
	@include icon($menu);
}