// ----------------------------------------------------------
// =Breadcrumbs
// ----------------------------------------------------------

.breadcrumbs {
	@extend %list-reset;
	@extend %hide-on-print;
	@extend %clearfix;
	display: none;
	color: palette(primary-font);
	margin-bottom: 40px;
	margin-left: 1rem;

	@include mq(lg){
		display: block;
	}
}
	.breadcrumbs__item {
		display: inline-block;
		margin: 0;

		&:after {
			font-size: 1rem;
			color: $white;
			font-weight: lighter;
			content: '/';
			display: inline-block;
			margin: 0;
		}

		// Remove arrow from last
		&:last-of-type:after {
			display: none;
		}
	}

	.breadcrumbs__item:nth-last-child(2){

		&:after{
			content: '';
		}
	}

		.breadcrumbs__link {
			color: $white;
			font-family: $secondary-font-stack-light;
			text-decoration: none;
			font-size: 1.2rem;

			&:visited {
				color: $white;
			}
			&:hover {
				text-decoration: underline;
				color: $white;
			}
		}

		.breadcrumbs__text {}
