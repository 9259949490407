$cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);
$menuItems: 14;

.navigation__mobile--custom {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    height: 5px;
    background: rgba(#252525,0);
    width: 100%;
    overflow: hidden;
    transition: all 0.5s ease-out, background 1s ease-out;
    transition-delay: 0.2s;
    z-index: 100;

    ul.menu {
        position: relative;
        display: block;
        padding: 0 22px;
        list-style: none;

        li.menu-item {
            margin-top: 5px;
            transform: scale(1.15) translateY(-30px);
            opacity: 0;
            transition: transform 0.5s $cubic, opacity 0.6s $cubic;
            @for $i from 1 through $menuItems {
                &:nth-child(#{$i}) {
                    transition-delay: #{0.56 - ($i * 0.07)}s;
                }
            }

            a {
                font-family: $primary-font-stack;
                display: block;
                position: relative;
                color: #FFF;
                font-weight: 100;
                text-decoration: none;
                font-size: 1.375rem;
                font-weight: 200;
                width: 100%;
                text-transform: uppercase;
                line-height: 1.8rem;
                padding-top: 1rem;
                height: 3rem;

                &:visited {
                    color: #ffffff;
                }

                &:active,
                &:hover {
                    color: $yellow;
                    width: fit-content;
                    border-bottom: 1px solid $yellow;
                }
            }

            a.selected-mainmmenu-item:first-child {
                &:hover,
                &:link,
                &:visited,
                a:active {
                    color: $yellow;
                    width: fit-content;
                    border-bottom: 1px solid $yellow;
                }
            }

            a.primary-button {
                font-size: 1.1rem;
                height: fit-content;
                color: $black;
                padding: 0.8rem 0;
                line-height: normal;
                min-height: 0;
                font-weight: normal;
                width: 100%;

                &:active,
                &:visited {
                    color: $black;
                }

                &:hover {
                  display: block;
                }
            }

            a.septenary-button {
                font-size: 1.1rem;
                height: fit-content;
                color: $black;
                padding: 0.8rem 0;
                line-height: normal;
                min-height: 0;
                font-weight: normal;
                width: 100%;

                &:active,
                &:visited {
                    color: $black;
                    display: block;
                    width: 100%;
                }

                &:hover {
                  display: block;
                  width: 100%;
                }
            }
        }

        ul.submenu {
            display: block;
            padding-left: 0;
            list-style: none;
            position: relative;
            opacity: 0;
            height: 0;

            li.submenu-item {
                height: 0;
                opacity: 0;
                transform: scale(1.15) translateY(0px);
                transition: transform 0.5s $cubic, opacity 0.6s $cubic;

                a {
                    color: #ffffff;
                    font-size: 0.8rem;
                    font-family: $primary-font-stack;
                    font-weight: 200;
                    text-decoration: none;
                    text-transform: capitalize;
                    display: block;
                    width: 100%;

                    &:visited{
                      color: #ffffff;
                      border: none;
                    }

                    &:hover, &:active{
                      color: $yellow;
                      font-weight: normal;
                      border: none;
                    }
                }
            }
        }

        ul.submenu-open {
            height: 100%;
            opacity: 1;

            li.submenu-item {
                height: 100%;
                display: block;
                transform: scale(1) translateY(0px);
                opacity: 1;
                @for $i from 1 through $menuItems {
                    &:nth-child(#{$i}) {
                        transition-delay: #{0.07 * $i+0.2}s;
                    }
                }
            }
        }
    }

    &.menu-opened {
        z-index: 50;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        background-color: #252525;
        transition: background 0.5s ease-in;
        // transition-delay: 0.25s;

        ul.menu {
            li.menu-item {
                transform: scale(1) translateY(0px);
                opacity: 1;
                @for $i from 1 through $menuItems {
                    &:nth-child(#{$i}) {
                        transition-delay: #{0.07 * $i+0.2}s;
                    }
                }
            }
        }
    }
}

.navigation__mobile-search-top {
    height: 4rem;
    margin-top: 2.3rem;
    display: block;

    .mobile__search-icon {
        display: inline-block;
        background: url("/img/icons/search-mobile-white.png") no-repeat center center;
        background-size: contain;
        height: 1.5rem;
        width: 1.5rem;
        float: left;
    }

    .mobile__search-input {
        display: inline-block;
        float: left;
        width: 75%;

        input {
            background: rgba(#252525,0);
            color: #ffffff;
            font-family: $primary-font-stack;
            font-weight: 200;
            height: 1.7rem;
            border: none;
        }

        input::placeholder {
            font-family: $primary-font-stack;
        }
    }

    .mobile__search-close {
        display: inline-block;
        background: url("/img/icons/menu-mobile-close.png") no-repeat center center;
        background-size: contain;
        height: 1.2rem;
        width: 1.2rem;
        float: right;
    }
}
