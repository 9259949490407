.m__static-content {
    max-width: 1200px;
    padding: 0 0.75rem;
    margin: 0 auto;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    padding-top: 2rem;

   .m__static-content--left {
    flex: 1 1 auto;
    width: 100%;

    @include mq(md) {
        width: 50%;
    }

    img {
        max-width: 36rem;
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
        display: block;
        margin: 0 auto;
        margin-bottom: 2rem;
    }

    p {
        text-align: center;
    }
   }

   .m__static-content--right {
    flex: 1 1 auto;

    ul {
        padding-left: 22px;
    }
   }
}
