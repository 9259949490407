#search-results {
	.content-listing {
	  margin-top: 40px;
	  margin-left: 0;
	  margin-right: 0;

	  .content-listing__item {
		padding-bottom: 20px;
		margin-bottom: 40px;
	  }
	}
}
