.video-hero {
	//@extend .container;
	position: relative;
	height: 400px;
	overflow: hidden;
	width: 100%;
	margin-top: -($mobile-header-height);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

	@include mq(sm){
		height: 420px;
		padding: 0;
	}

	@include mq(md){
		top: -($header-height);
		height: 500px;
		margin-bottom: -($header-height); 
	}
	
	@include mq(lg){
		height: 665px;	
	}
}

.video-hero__img{
	@include translate(-50%, 0);
	position: relative;
	width: auto;
	height: 100%;
	left: 50%;
	top: 0;

	@include mq(sm){
		min-width: 100%;
		height: 100%; 
	}

	@include mq(xlg){
		min-width: 100%;
		height: auto;
	}
}

.video-hero__img--desktop {
	@extend .video-hero__img;
	display: none;

	@include mq(sm){
		display: block;
	}
}

.video-hero__img--mobile{
	@extend .video-hero__img;

	@include mq(sm){
		display: none;
	}
}

.video-hero__content {
	@include translate(-50%, 0);
	left: 50%;
	bottom: 20px;
	position: absolute;
	text-align: center;

	@include mq(sm){
		@include translate(-50%, -50%);
		margin-top: 40px;
		top: 50%;
		bottom: auto;
	}

	@include mq(md){
		margin-top: 80px;
	}
}

.video-hero__content-button {
	width: 160px;
	font-size: font(size-m);
	display: block;
	margin: 0 auto 5px;	

	@include mq(sm){
		display: inline-block;
		margin: 0;
	}
}

.video-hero__content-main-heading {
	font-family: font(bebas);
	font-size: font(size-xxxxl);
	text-transform: uppercase;
	line-height: 1;
	padding-bottom: 23px;

	@include mq(sm){
		font-size: 135px;
	}
}

.video-hero__content-top-heading {
	font-family: font(condensed);
	font-size: font(size-l);
	text-transform: uppercase;
	line-height: 1;
}