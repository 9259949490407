.search-registrars {
  .sr-search-input {
    display: flex;

    .sr-text-input {
      input {
        padding: 6px 15px;
        min-width: 20rem;
      }
    }

    .sr-button-input {
      padding-left: 1rem;
    }
  }

  .sr-results {
    margin-top: 3rem;

    .sr-individual-result {
      padding: 1rem;
      text-decoration: none;
      display: block;

      &:hover,
      &:active,
      &:focus {
        background-color: $faded-white;
      }
    }
  }
}
