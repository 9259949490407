.home-promotional-banner{

    background-position: top center;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    padding: 3rem 2rem;
    overflow: hidden;

    &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        width: auto;
        height: 40rem;
        background-image: linear-gradient(to bottom,
        rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0));
    }

    &--wrapper{
        
        max-width: $container-small-max-width;
        text-align: center;
        z-index: 5;

        @include mq(md){
            padding: 6rem 0;
        }

        @include mq(lg){
            padding: 13rem 0;
        }
    }

    &__small-text{
        line-height: 1.8rem;
        font-weight: 200;
        font-size: 1.2rem;
        font-family: $primary-font-stack;
        text-transform: uppercase;
        color: #ffffff;

        @include mq(sm){
            font-size: 2.2rem;
            line-height: 2.2rem;
            font-weight: 200;
        }

        @include mq(md){
            font-size: 1.6rem;
            line-height: 1.25rem;
        }

        @include mq(lg){
            font-size: 2.8rem;
            line-height: 2.2rem;
            font-weight: 300; 
        }
    }

    &__large-text{
        font-size: 5.8rem;
        line-height: 5rem;
        letter-spacing: -0.3rem;
        font-family: $primary-font-stack;
        text-transform: uppercase;
        color: #ffffff;

        @include mq(sm){
            font-size: 9.5rem;
            line-height: 9rem;
            letter-spacing: -0.55rem;
        }

        @include mq(md){
            line-height: 7.5rem;
            font-size: 8rem;
            letter-spacing: -0.55rem;
        }

        @include mq(lg){
            line-height: 11rem;
            font-size: 14rem;
            letter-spacing: -0.55rem;   
            margin-top: 1.7rem;
        }
    }

    &__paragraph-text{
        margin-top: 1.6rem;
        font-family: $secondary-font-stack-light;
        font-size: 1.3rem;
        color: #ffffff;
    }

    &__buttons{
        margin-top: 2.2rem;

        a{
            font-size: 1.1rem;
            min-height: 1.8rem;
            padding: .8rem 2.3rem;
            width: 100%;
        }

        a:nth-child(2){
            margin-top: 0.3rem;
        }

        @include mq(sm){
            a{
                font-size: 1.1rem;
                min-height: 1.7rem;
                padding: .8rem;
                width: auto;
            }

            a:nth-child(2){
                margin-top: 0;
            }
        }

        @include mq(md){
            a{
                font-size: 1.5rem;
                width: auto;
            }

            a:nth-child(2){
                margin-top: 0;
            }
        }
    }
}
