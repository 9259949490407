/*
	jQuery.mmenu searchfield addon CSS
*/

.mm-search,
.mm-search input
{
	@include mm_vendor-prefix( "box-sizing", border-box );
}

.mm-list 
{
	> li.mm-search
	{
		padding: $mm_padding;
		margin-top: -( $mm_padding * 2 );
	}
	> li.mm-subtitle + li.mm-search
	{
		//margin-top: 0;
	}
}
div.mm-panel > div.mm-search
{
	padding: 0 0 $mm_padding 0;
}
.mm-menu.mm-hasheader .mm-list > li.mm-search
{
	margin-top: 0;
}
.mm-search
{

	background: inherit;
	width: 100%;
	padding: map-get($mobile-menu, search-margins);
	position: relative;
	top: 0;
	z-index: 2;
	height: $mm_searchfieldHeight + (map-get($mobile-menu, search-margins) * 2);

	input
	{		
		border: none;
		border-radius: map-get($mobile-menu, search-border-radius);
		font: inherit;
		line-height: $mm_searchfieldHeight;
		outline: none;
		display: block;
		width: 100%;
		height: $mm_searchfieldHeight;
		margin: 0;
		padding: 0 ($mm_padding * 2);
		background: map-get($mobile-menu, search-background);
	}
}

.mm-menu .mm-noresultsmsg
{
	text-align: center;
	font-size: round( $mm_fontSize * 1.5 );
	display: none;
	padding: ( $mm_btnSize * 1.5 ) 0;
	
	&:after
	{
		border: none !important;
	}
}
.mm-noresults .mm-noresultsmsg
{
	display: block;
}

$mm_paddingBeneathHeader: $mm_padding * 2;
.mm-menu
{
	li.mm-nosubresults > a.mm-subopen
	{
		display: none;
	
		+ a, 
		+ span
		{
			padding-right: 10px;
		}
	}
	&.mm-hassearch 
	{
		> .mm-panel
		{
			padding-top: $mm_searchHeight + (map-get($mobile-menu, search-margins) * 2);
			
			> .mm-list:first-child
			{
				margin-top: -$mm_paddingBeneathHeader;
			}
		}
	}
	&.mm-hasheader
	{
		> .mm-panel
		{
			> div.mm-search:first-child
			{
				margin-top: -$mm_padding;
				
				+ .mm-list
				{
					padding-top: 0;
				}
			}
		}
	}
}

@include mm_colors_search;