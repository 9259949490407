.sponsors {
	max-width: 1500px;
	margin: 0 auto -30px;
	position: relative;
	padding: 0 40px 35px 40px;

	@include mq(md) {
		margin-bottom: -60px;
	}
}

	.sponsors__list {
		@extend %list-reset;
		overflow: hidden;
	}

	.sponsors__item {
		@extend %list-item-reset;
		@include grid-col(1,2);
		text-align: center;
		height: 100%;
		position: relative;
		padding: 1.2rem 2.4rem 2.4rem 2.4rem;

		@include mq(xs) {
			@include grid-col(1,3);
		}

		@include mq(sm) {
			@include grid-col(1,4);
		}

		@include mq(md) {
			@include grid-col(1,5);
		}

		@include mq(lg) {
			@include grid-col(1,6);
		}

		@include mq(xlg) {
			@include grid-col(1,6);
		}

		div {
			@include translate(0, -50%);
			display: block;
			position: relative;
			top: 50%;
		}

		img {
			max-width: 100%;
			height: auto;
		}
	}

	.sponsors__control {
		@include translate(0, -50%);
		background-position: center center;
		background-repeat: no-repeat;
		cursor: pointer;
		height: 59px;
		position: absolute;
		top: 35%;
		width: 31px;
	}
	.sponsors__control--next {
		@extend .sponsors__control;
		background-image: url("#{$icons}arrow-next.png");
		right: 0;
	}

	.sponsors__control--prev {
		@extend .sponsors__control;
		background-image: url("#{$icons}arrow-prev.png");
		left: 0;
	}
