
.template-news,
.template-calendar,
.template-listing {

	.content__meta {
		color: $white;
	}

		.content__meta-inner {
			overflow: hidden;
		}

			.breadcrumbs__link {
				color: $white;
			}

			.content__title {
				margin-bottom: 1rem;
				font-family: $primary-font-stack;
				font-size: 1.5rem;
				color: $yellow;

				@include mq(sm){
					font-size: 1.8rem;
				}
	
				@include mq(md){
					font-size: 2rem;
				}
	
				@include mq(lg){
					font-size: 2.5rem;
				}
			}
	.content__main {
			display: block;

			@include mq(sm){
				display: block;
			}
		}
}

.template-calendar .content__meta-inner{
	position: relative;
}

.news__pager {
	float: right;
}
	.news__prev,
	.news__next {
		float: left;
		width: 22px;
		height: 44px;
		height: font(size-xxxl);
		background: url('#{$sprites}arrows-yellow.png') left center no-repeat;
		cursor: pointer;
	}
	.news__next {
		background-position: right center;
		margin-left: 30px;
	}
