.content-listing {
	margin-bottom: -60px;
	// margin-top: -60px;
	margin-left: -(map-get($outer-grid, gutter)/2);
	margin-right: -(map-get($outer-grid, gutter)/2);

	@include mq(sm){
		margin-left: -20px;
		margin-right: -20px;	
	}

	@include mq(md){
		margin-left: -40px;
		margin-right: -40px;
	}

	@include mq(lg){
		margin-left: -75px;
		margin-right: -75px;
	}
}

	.content-listing__listing {
		@extend %list-reset;
	}

	.content-listing__item {
		@extend %list-item-reset;
		border-bottom: 1px solid $almost-white;
		padding: 20px (map-get($outer-grid, gutter)/2);

		@include mq(sm){
			padding: 20px;
		}

		@include mq(md){
			padding: 30px 40px;
		}

		@include mq(lg){
			padding: 60px 75px;
		}
	}

	.content-listing__item-title {
		margin-bottom: 20px;
	}

	.content-listing__item-summary {
		margin-bottom: 25px;
	}

	.content-listing{
		ul.content-listing__listing {

			li.content-listing__item {
				padding-bottom: 3rem;
				margin-bottom: 3rem;
			}

			li:last-child {
				border-bottom: none;
			}
		}
	}
