.member-profile {
	padding-top: 30px;
	padding-bottom: 30px;

	@include mq(md) {
		padding-top: 60px;
		padding-bottom: 60px;		
	}
}

	.member-profile__inner {
		@extend .content-container;
	}

	.member-profile__tab {
		@include flex-container();
		@extend .row;
	}

	.member-profile__header-tab {
		@extend h2;
		@include grid-col(1,2);
		width: 49%;
		float: left;
		text-decoration: none;
		margin-bottom: 0;
	    padding-bottom: 10px;
	    padding-top: 10px;
	    cursor: pointer;
	    
	    border-bottom: none;
	    font-size: font(size-l);

	    &.active {
	    	background-color: $almost-white;
	    	box-shadow: 6px -5px 4px -2px #7f7f7f;
	    	cursor: default;
	    }
	    span{
	    	font-size: 16px;
	    }
	    @include mq(sm) {
			font-size: font(size-xl);
	    }

	    @include mq(md) {
			font-size: font(size-xxl);
	    }
	}
	
		
	.member-profile__header-tab--clear {
		@extend .member-profile__header-tab;
		border: 2px solid $white;
		width: 90%;

		&.active {
	    	background-color: $white;
	    }
	}

	.member-profile__form {
		z-index: 2;
		background-color: $almost-white;
		width: 100%; 
	}
	.member-profile__form--clear {
		@extend .member-profile__form;
		background-color: $white;
	}

	.member-profile__form-inner {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.member-profile__form-row {
		overflow: hidden;
	}

	.member-profile__form-container {
		position: relative;
		margin-bottom: 20px;
		width: 100%;
		overflow: hidden;
	}
	.member-profile__form-container--file {
		@extend .member-profile__form-container;
	}
	.member-profile__form-container--half {
		@extend .member-profile__form-container;
		width: 50%;
		float: left;

		@include mq(sm) {
			width: auto;
			margin-right: 15px;
		}	
	}

	.member-profile__form-file-link {
		display: none;
		float: left;
		margin: 15px 0 15px 15px;
		width: 100px;
		text-decoration: none;
	    line-height: 30px;
	    font-size: font(size-xxs);

	    @include mq(sm) {
	    	position: absolute;
	    	top: 13px;
			left: 100%;
			margin: 0 0 0 15px;
	    }
	}

	.member-profile__form-select {
		@include mq(sm) {
			min-width: 320px;
			max-width: 100%;
		}		
	}

	.member-profile__form-input {
		max-width: 100%;

		&.readonly {
			background-color: $almost-white; 
		}

		@include mq(sm) {
			width: 320px;
		}
	}
	.member-profile__form-input--long {
		@extend .member-profile__form-input;

		@include mq(sm) {
			width: 360px;
		}
	}
	.member-profile__form-input--date {
		@extend .member-profile__form-input;
		padding-right: 15px;
		padding-left: 15px;
		background: url("#{$icons}calendar.png") no-repeat right center $white;
	}
	.member-profile__form-input--clock {
		@extend .member-profile__form-input--date;
		background-image: url("#{$icons}clock.png");
	}
	.member-profile__form-input--plus {
		@extend .member-profile__form-input--date;
		background-image: url("#{$icons}plus.png");
	}
	.member-profile__form-input--file {
		@extend .member-profile__form-input;
		opacity: 0;
	}
	.member-profile__form-input--url {
		@extend .member-profile__form-input;
		padding-left: 75px;
	}

	.member-profile__form-textarea {
		@extend .member-profile__form-input;
		@include mq(sm) {
			width: 600px;
		}
	}

	.member-profile__form-container-file {
		position: relative;
		@include mq(sm) {
			float: left;	
		}
	}

	.member-profile__form-label--file {
		@extend input;
		@extend .member-profile__form-input--date;
		background-image: url("#{$icons}plus.png");
		position: absolute;
		width: 100%;
		height: 100%;	
		color: $dark-grey;
		top: 0;
		left: 0;
		padding-top: 10px;
	}

	.member-profile__form-buttons {

	}

	.member-profile__form-cvv {
		position: absolute;
		right: 10px;
		top: 10px;
		display: block;
		width: 30px;
		height: 30px;
		text-indent: -9999px;
		background: url("#{$icons}question-mark.png") no-repeat center center;
		background-size: cover; 

		@include mq(sm) {
			right: auto;
			left: 328px;
		}
	}

	.member-profile__form-submit,
	.member-profile__form-close,
	.member-profile__form-save-as-new {
		margin-bottom: 15px;

		@include mq(sm) {
			margin-bottom: 0;
		}
	}
 
	.member-profile__form-label-currency {
		position: absolute;
		top: 11px;
		right: 15px;

		@include mq(sm) {
			left: 267px;
			right: auto;
		}
	}

	.member-progile__list {
		@extend %list-reset;

		li {
			margin-bottom: 15px;
		}		
	}