.home-features {
		@extend %list-reset;
		@include flex-container();
		width: 100%;

		@include mq(md) {
			width: 622px;
			position: absolute;
			top: 30px;
			right: 0;
		}

		@include mq(xlg) {
			width: 1350px;
		}

		img{
			width: 100%;
			height: auto;
		}
	}
	.static-feature-img{ 
		width: 100%;
		height: auto;
	}
	.happening-carousel{
		width: 100%;
		.happening-carousel__item-list{
			padding-left: 0;
			max-width: 100%;
			display: block;
			position: relative;
			.slick-dots{
			bottom: 7%!important;
			}
			.slick-dots li.slick-active button:before {
				border: 0.1875rem solid green!important;
			    background-color: green !important;
			}
			.slick-dots li button{

			}
			.slick-dots li button:before{
				width: 16px;  
				height: 16px;
				border-radius: 50%;
				background-color: white;
				opacity: 1;
			
				content: "";
			}	
	 
		}	
	}
	
	.home-feature__item {
		@extend %list-item-reset;
		@include flex-container(column);
		@include grid-col(1,1,0);

		float: left;
		display: block;
		position: relative;

		@include mq(600px){
			@include grid-col(1,2,0);
		}

		@include mq(xlg) {
			@include grid-col(1,3,0);
		}

		&--social{
			@extend .home-feature__item;
			background: $green;

		}

		&--fact{
			@extend .home-feature__item;
			background: $yellow;
		}
	}

	.home-feature__img{
		display: block;
		width: 100%;
		height: auto;
	}

	.home-feature__wrapper{
		position: static;
		padding: 60px 15px;
		text-decoration: none;
		font-family: font(medium);
		

		@include mq(600px){
			@include translate(0, -50%);
			position: absolute;
			top: 50%;
			left: 0;
			// width: 100%;
			right: 0;
			z-index: 2;
			padding: 0 15px;
			
		}
	}

	.home-feature__wrapper--social{
		@extend .home-feature__wrapper;
		color: $white;
	}

	.home-feature__wrapper--fact{
		@extend .home-feature__wrapper;
		color: $black;
	}

	.home-feature__text{
		font-size: font(size-xs);
	}

	%home-feature-link{
		display: block;
		width: 100%;
		max-width: 175px;
		margin: 0 auto;
		float: none;
		padding-top: 15px;
		text-align: left;
		color: $white;
		text-decoration: none;

		&:visited{
			color: $white;
		}

		&:hover{
			color: $black;
		}

		&:before {
			content: ' ';
			width: 35px;
			display: inline-block;
		}
	}

	.home-feature__twitter {
		@extend %home-feature-link;
		padding-top: 35px;
		&:before {
			background: url("#{$icons}twitter-white.png") left center no-repeat;
			height: 20px;
		}

		@include mq(xlg){
			padding-top: 50px;
		}

	}

	.home-feature__facebook {
		@extend %home-feature-link;
		&:before {
			background: url("#{$icons}facebook-white.png") left center no-repeat;
			height: 25px;
		}

	}

	.home-feature__instagram {
		@extend %home-feature-link;
		&:before {
			background: url("#{$icons}instagram-white.png") left center no-repeat;
			height: 25px;
		}

	}