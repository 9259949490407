.image-grid {
	@extend .content-container;
}

	.image-grid__listing {
		@extend .row;
		padding: 0;
		list-style: none;
		margin-bottom: -15px; // To counter the bottom margin added by the last items
	}

	.image-grid__item {
		@extend %list-item-reset;
		@include grid-col(1,1);
		position: relative;
		margin-bottom: 15px;

		@include mq(sm) {
			@include grid-col(1,3);
		}
	}

	.image-grid__item-image {
		display: inline-block;
		overflow: hidden;
		position: relative;

		&:before {
			content: ' ';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: $black;
			opacity: 0.3;
			z-index: 1;
		}

		img {
			@include transition(all ease-in-out 500ms);
			max-width: 100%;
			height: auto;

			.image-grid__item:hover & {
				@include scale(1.1);
			}	
		}
	}

	.image-grid__item-link {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		color: $white;
		text-align: left;
		z-index: 2;
	}

	.image-grid__item-title {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		color: $white;
		padding: 20px 20px 30px 20px;		
		z-index: 2;
	}