// Vendor Prefixes
//
// All vendor mixins are deprecated as of v3.2.0 due to the introduction of
// Autoprefixer in our Gruntfile. They will be removed in v4.

//   animation($animation)
//   animation-name($name)
//   animation-duration($duration)
//   animation-timing-function($timing-function)
//   animation-delay($delay)
//   animation-iteration-count($iteration-count)
//   animation-direction($direction)
//   animation-fill-mode($fill-mode)

//   backface-visibility($visibility)

//   box-shadow($shadow...)
//   box-sizing($boxmodel)

//   content-columns($column-count, $column-gap: $grid-gutter-width)

//   hyphens($mode: auto)

//   placeholder($color: $input-color-placeholder)

//   scale($ratio...)
//   scaleX($ratio)
//   scaleY($ratio)
//   skew($x, $y)
//   translate($x, $y)
//   translate3d($x, $y, $z)
//   rotate($degrees)
//   rotateX($degrees) 
//   rotateY($degrees) 
//   perspective($perspective)
//   perspective-origin($perspective) 
//   transform-origin($origin)

//   transition($transition...)
//   transition-property($transition-property...)
//   transition-delay($transition-delay)
//   transition-duration($transition-duration...) 
//   transition-timing-function($timing-function)
//   transition-transform($transition...)

//   user-select($select)
//   placeholder-text($selector: input)
//   linear-gradient($fromcolour, $tocolour, $direction: top)
//   css-columns($count, $column-gap)
//   keyframes($animation-name) 

     // border-radius($radius)
     // border-radius-top-left($radius)
     // border-radius-top-right($radius)
     // border-radius-bottom-left($radius)
     // border-radius-bottom-right($radius)

// ----------------------------------------------------------------------------------------------




// Animations
@mixin animation($animation) {
  -webkit-animation: $animation;
  -o-animation: $animation;
  -moz-animation: $animation;
  -ms-animation: $animation;
  animation: $animation;

}
@mixin animation-name($name) {
  -webkit-animation-name: $name;
          animation-name: $name;
}
@mixin animation-duration($duration) {
  -webkit-animation-duration: $duration;
          animation-duration: $duration;
}
@mixin animation-timing-function($timing-function) {
  -webkit-animation-timing-function: $timing-function;
          animation-timing-function: $timing-function;
}
@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
          animation-delay: $delay;
}
@mixin animation-iteration-count($iteration-count) {
  -webkit-animation-iteration-count: $iteration-count;
          animation-iteration-count: $iteration-count;
}
@mixin animation-direction($direction) {
  -webkit-animation-direction: $direction;
          animation-direction: $direction;
}
@mixin animation-fill-mode($fill-mode) {
  -webkit-animation-fill-mode: $fill-mode;
          animation-fill-mode: $fill-mode;
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`

@mixin backface-visibility($visibility){
  -webkit-backface-visibility: $visibility;
     -moz-backface-visibility: $visibility;
          backface-visibility: $visibility;
}

// Drop shadows
//
// Note: Deprecated `.box-shadow()` as of v3.1.0 since all of Bootstrap's
// supported browsers that have box shadow capabilities now support it.

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
          box-shadow: $shadow;
}

// Box sizing
@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
     -moz-box-sizing: $boxmodel;
          box-sizing: $boxmodel;
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
  -webkit-column-count: $column-count;
     -moz-column-count: $column-count;
          column-count: $column-count;
  -webkit-column-gap: $column-gap;
     -moz-column-gap: $column-gap;
          column-gap: $column-gap;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
  word-wrap: break-word;
  -webkit-hyphens: $mode;
     -moz-hyphens: $mode;
      -ms-hyphens: $mode; // IE10+
       -o-hyphens: $mode;
          hyphens: $mode;
}

/**
 * Style placeholder text
 *
 * e.g @include placeholder{ color: red; }
 */
@mixin placeholder() {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }

  &::-moz-placeholder {  /* Firefox 19+ */
    @content;
  }

  &:-ms-input-placeholder {  
    @content;
  }
}

// Transformations
@mixin scale($ratio...) {
  -webkit-transform: scale($ratio);
      -ms-transform: scale($ratio); // IE9 only
       -o-transform: scale($ratio);
          transform: scale($ratio);
}

@mixin scaleX($ratio) {
  -webkit-transform: scaleX($ratio);
      -ms-transform: scaleX($ratio); // IE9 only
       -o-transform: scaleX($ratio);
          transform: scaleX($ratio);
}
@mixin scaleY($ratio) {
  -webkit-transform: scaleY($ratio);
      -ms-transform: scaleY($ratio); // IE9 only
       -o-transform: scaleY($ratio);
          transform: scaleY($ratio);
}
@mixin skew($x, $y) {
  -webkit-transform: skewX($x) skewY($y);
      -ms-transform: skewX($x) skewY($y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
       -o-transform: skewX($x) skewY($y);
          transform: skewX($x) skewY($y);
}
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
      -ms-transform: translate($x, $y); // IE9 only
       -o-transform: translate($x, $y);
          transform: translate($x, $y);
}
@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
          transform: translate3d($x, $y, $z);
}
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
      -ms-transform: rotate($degrees); // IE9 only
       -o-transform: rotate($degrees);
          transform: rotate($degrees);
}
@mixin rotateX($degrees) {
  -webkit-transform: rotateX($degrees);
      -ms-transform: rotateX($degrees); // IE9 only
       -o-transform: rotateX($degrees);
          transform: rotateX($degrees);
}
@mixin rotateY($degrees) {
  -webkit-transform: rotateY($degrees);
      -ms-transform: rotateY($degrees); // IE9 only
       -o-transform: rotateY($degrees);
          transform: rotateY($degrees);
}
@mixin perspective($perspective) {
  -webkit-perspective: $perspective;
     -moz-perspective: $perspective;
          perspective: $perspective;
}
@mixin perspective-origin($perspective) {
  -webkit-perspective-origin: $perspective;
     -moz-perspective-origin: $perspective;
          perspective-origin: $perspective;
}
@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
     -moz-transform-origin: $origin;
      -ms-transform-origin: $origin; // IE9 only
          transform-origin: $origin;
}
@mixin transform($transform...) {
  -webkit-transform: $transform;
      -ms-transform: $transform; // IE9 only
       -o-transform: $transform;
          transform: $transform;
}


// Transitions

@mixin transition($transition...) {
  -webkit-transition: $transition;
       -o-transition: $transition;
     -moz-transition: $transition;
     -ms-transition: $transition;
          transition: $transition;
}
@mixin transition-property($transition-property...) {
  -webkit-transition-property: $transition-property;
          transition-property: $transition-property;
}
@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
          transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration...) {
  -webkit-transition-duration: $transition-duration;
          transition-duration: $transition-duration;
}
@mixin transition-timing-function($timing-function) {
  -webkit-transition-timing-function: $timing-function;
          transition-timing-function: $timing-function;
}
@mixin transition-transform($transition...) {
  -webkit-transition: -webkit-transform $transition;
     -moz-transition: -moz-transform $transition;
       -o-transition: -o-transform $transition;
          transition: transform $transition;
}


// User select
// For selecting text on the page

@mixin user-select($select) {
  -webkit-user-select: $select;
     -moz-user-select: $select;
      -ms-user-select: $select; // IE10+
          user-select: $select;
}


// ------------------------------------------------------------
// Some more custom mixins
// -------------------------------------------------------------



  //  placeholder-text
  //  Style placeholder text
  //
  //  @param selector   :  string // Defaults to input, but can be overridden for specific element styling
  //
  @mixin placeholder-text($selector: input) {

    // Chrome
    #{$selector}::-webkit-input-placeholder {
      @content;
    }

    // Firefox 18-
    #{$selector}:-moz-placeholder {
      @content;
    }

    // Firefox 19+
    #{$selector}::-moz-placeholder {
      @content; 
    }

    // IE
    #{$selector}:-ms-input-placeholder {  
      @content;
    }
  }


  //  linear-gradient
  //  Basic linear gradient
  //
  //  @param fromcolour   :  hex colour
  //  @param tocolour   :  hex colour
  //  @param direction  :  string // Direction for the gradient to go to, defaults to top
  //
  @mixin linear-gradient($fromcolour, $tocolour, $direction: top) {
    background: $tocolour;
    background: -moz-linear-gradient($direction, $fromcolour 0%, $tocolour 100%);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,$fromcolour), color-stop(100%,$tocolour));
    background: -webkit-linear-gradient($direction, $fromcolour 0%,$tocolour 100%);
    background: -o-linear-gradient($direction, $fromcolour 0%,$tocolour 100%);
    background: -ms-linear-gradient($direction, $fromcolour 0%,$tocolour 100%);
    background: linear-gradient($direction, $fromcolour 0%,$tocolour 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$fromcolour}', endColorstr='#{$tocolour}',GradientType=1 );
  }


  //  css-columns
  //  Break text into CSS columns
  //
  //  @param count    :  int // Number of columns to split into
  //  @param column-gap   :  px, em // Spacing between columns
  //
  @mixin css-columns($count, $column-gap) {
    -webkit-column-count: $count;
    -webkit-column-gap: $column-gap;

    -moz-column-count: $count;
    -moz-column-gap: $column-gap;

    column-count: $count;
    column-gap: $column-gap;
  }

//  keyframes
  //  Generate prefixed animation keyframes
  //
  //  @param animation-name   :  string
  //
  @mixin keyframes($animation-name) {
    @-webkit-keyframes $animation-name {
      @content;
    }
    @-moz-keyframes $animation-name {
      @content;
    }
    @-ms-keyframes $animation-name {
      @content;
    }
    @-o-keyframes $animation-name {
      @content;
    }
    @keyframes $animation-name {
      @content;
    }
  }

// Border Radius
// $radius      : px

@mixin border-radius($radius){
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
}

@mixin border-radius-top-left($radius){
  border-top-left-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  -ms-border-top-left-radius: $radius;
  -o-border-top-left-radius: $radius;
}

@mixin border-radius-top-right($radius){
  border-top-right-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
  -ms-border-top-right-radius: $radius;
  -o-border-top-right-radius: $radius;
}

@mixin border-radius-bottom-left($radius){
  border-bottom-left-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  -ms-border-bottom-left-radius: $radius;
  -o-border-bottom-left-radius: $radius;
}

@mixin border-radius-bottom-right($radius){
  border-bottom-right-radius: $radius;
  -webkit-border-bottom-right-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  -ms-border-bottom-right-radius: $radius;
  -o-border-bottom-right-radius: $radius;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin flex-container($flex-flow: row wrap) {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: $flex-flow;
  flex-flow: $flex-flow;
}

@mixin flex($value: 0 1 auto) {
  -webkit-flex: $value;
  flex: $value;
}