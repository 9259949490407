// ----------------------------------------------------------
// =General Vars
// ----------------------------------------------------------

	$using-modernizr: true; 	// whether or not modernizer feature-detection classes are being used
	$ie8: false; 				// Do we need to support ie8 filters (used in mixins)

	$vertical-margins: 20px;	// Margins below elements


// ----------------------------------------------------------
// =Paths
// ----------------------------------------------------------

	$base:							"/";
	$css:							"#{$base}css/";
	$images:						"#{$base}img/";
	$backgrounds: 					"#{$images}backgrounds/";
	$icons: 						"#{$images}icons/";
	$sprites: 						"#{$images}sprites/";
	$js:							"#{$base}js/";
	$fonts: 						"#{$css}fonts/";


// ----------------------------------------------------------
// =Colours
// ----------------------------------------------------------
	$yellow: #ffcd03; // also #ffcb09
	$green: #92ce98;
	$blue: #00ACED;

	// Generic greyscale colours
	// NOTE: Other grey is done with opacity
	$white:					#fff;
	$almost-white:			#f8f8f8;
	$light-grey:			#cccccc;
	$grey:					#e5e5e5;
	$dark-grey:             #626262;
	$almost-black:			#0b0c0c;
	$black:					#000;
	$faded-white: #f5f5f5;
	$faded-yellow: #ffe88a;
	$grey-text: #6d6d6d;
	$blue-link: #2e7dff;
	$left-pane-colour: #f5f5f5;

	$navigation-hover-color: #252525;

	// Access these using palette(brand-colour);
	$palette: (

		// Main brand colour
		brand-colour: $yellow,
		brand-colour-light: lighten($yellow, 10%),
		brand-colour-dark: darken($yellow, 10%),

		// Secondary brand colour
		secondary-brand-colour: $grey,
		secondary-brand-colour-light: lighten($grey, 10%),
		secondary-brand-colour-dark: darken($grey, 10%),

		// Tertiary brand colour
		tertiary-brand-colour: $grey,
		tertiary-brand-colour-light: lighten($grey, 10%),
		tertiary-brand-colour-dark: darken($grey, 10%),

		// Primary background colours and matching text
		primary-background: $white,
		primary-text: $black,

		// Secondary background and matching text
		secondary-background: $grey,
		secondary-text: $black,

		// Default link colours
		link: $black,
		link-hover: $black,
		link-active: $black,
		link-visited: $black,

		// Default nav colours
		nav: $grey,
		nav-hover: lighten($grey, 10%),
		nav-active: lighten($grey, 10%),
		nav-visited: darken($grey, 10%),
		nav-background: $white,
		nav-background-hover: $grey,
		nav-background-active: $yellow,

			// Nav dropdown
			nav-dropdown-hover: lighten($grey, 10%),
			nav-dropdown-active: lighten($grey, 10%),
			nav-dropdown-visited: darken($grey, 10%),
			nav-dropdown-background: $white,
			nav-dropdown-background-hover: $grey,
			nav-dropdown-background-active: $yellow,

		// Buttons
		primary-button: $yellow,
		primary-button-hover: $faded-yellow,
		primary-button-active: $faded-yellow,
		primary-button-text: $black,
		primary-button-text-active: $black,

		// Secondary buttons
		secondary-button: $black,
		secondary-button-hover: $faded-white,
		secondary-button-active: $faded-white,
		secondary-button-text: $white,
		secondary-button-text-active: $black,

		// Tertiary buttons
		tertiary-button: $grey,
		tertiary-button-hover: lighten($grey, 10%),
		tertiary-button-active: lighten($grey, 10%),
		tertiary-button-text: lighten($white, 10%),

		//Quaternary buttons
		quaternary-button: $black,
		quaternary-button-hover: $yellow,
		quaternary-button-active: $yellow,
		quaternary-button-text: $white,
		quaternary-button-text-active: $black,

		//Quinary buttons
		quinary-button: $black,
		quinary-button-hover: $faded-white,
		quinary-button-active: $faded-white,
		quinary-button-text: $white,
		quinary-button-text-active: $black,

		//Senary buttons
		senary-button: $yellow,
		senary-button-hover: $faded-white,
		senary-button-active: $faded-white,
		senary-button-text: $black,
		senary-button-text-active: $black,

		//Septenary buttons
		septenary-button: $white,
		septenary-button-hover: $faded-white,
		septenary-button-active: $faded-white,
		septenary-button-text: $black,
		septenary-button-text-active: $black,


		// Text Selection colours
		selected-text-background: $yellow,
		selected-text-colour: lighten($white, 10%),

		// Border colour - used for HR and input fields
		primary-border-colour: $black,
		primary-border-colour-hover: $grey,
		primary-border-colour-active: $grey
	);


// ----------------------------------------------------------
// =Mobile menu - uses the jquery.mmenu plugin
// ----------------------------------------------------------

	// Mobile menu vars
	$mobile-menu: (

		// Outer background
		outer-background: $almost-white,

		// Link backgrounds
		background: $white,
		background-hover: $yellow,
		background-selected: $almost-white,

		// Link text
		text: $yellow,
		text-hover: $white,
		text-selected: $yellow,

		// General styles
		arrow-colour: $yellow,
		arrow-colour-hover: $white,
		selected-arrow-colour: $yellow,
		selected-arrow-colour-hover: $grey,
		border-colour: $almost-white,

		// Search box
		search-background: $white,
		search-colour: $grey,
		search-border-radius: 0,
		search-margins: 15px, // Padding separating the search box from other elements
		search-height: 50px,
		search-noresults-colour: map-get($palette, secondary-text)
	);


// ----------------------------------------------------------
// =Typography
// ----------------------------------------------------------
	$medium-stack: 'Futura LT W01 Medium', 'Arial', sans-serif;
	$light-stack: 'Futura LT W01 Light', 'Arial', sans-serif;
	$bold-stack: 'Futura LT W01 Bold', 'Arial', sans-serif;
	$beba-stack: 'Bebas W00 Regular', 'Arial', sans-serif;
	$condensed-stack: 'Futura LT W01 Medium Condensed', 'Arial', sans-serif;

	$primary-font-stack: 'stolzl', 'Arial', sans-serif;
	$secondary-font-stack: 'FuturTBoo', 'Arial', sans-serif;
	$secondary-font-stack-light: 'FuturTLig', 'Arial', sans-serif;

	// Access these using font(default);
	$font: (
		default: $primary-font-stack, // Main body font
		bold: $bold-stack,
		medium: $medium-stack,
		light: $light-stack,
		bebas: $beba-stack, // Should consider replacing with Condensed - only used for home hero and footer brightlabs link
		condensed: $condensed-stack,

		size-xxxxl: 84px,
		size-xxxl: 58px,
		size-xxl: 42px,   // also 43px
		size-xl: 36px,
		size-l: 30px,
		size-m: 24px,
		size-sm: 22px,
		size-xs: 20px,
		size-xxs: 18px,
		size-xxxs: 14px,
		size-xxxxs: 12px,
		size-tiny: 10px
	);

	// Headings
	$heading-sizes: (
		h1: map-get($font, size-xxl),
		h2: map-get($font, size-xl),
		h3: map-get($font, size-l),
		h4: map-get($font, size-m),
		h5: map-get($font, size-sm),
		h6: map-get($font, size-sm)
	);

	// Mobile Headings
	$heading-mobile-sizes: (
		h1: map-get($font, size-xxl),
		h2: map-get($font, size-xl),
		h3: map-get($font, size-m),
		h4: map-get($font, size-sm),
		h5: map-get($font, size-sm),
		h6: map-get($font, size-sm)
	);

// ----------------------------------------------------------
// =Guards and grids
// ----------------------------------------------------------

	// Guard/breakpoint sizes
	$guards: (
		xs: 460px,
		sm: 768px,
		md: 992px,
		lg: 1200px,
		xlg: 1800px
	);

	// Main layout grid, set grid defaults
	$outer-grid: (
		columns: 12,
		gutter: 24px,
		max-width: map-get($guards, lg),  // Set to 0 for fluid width
		output: false, // Set to true to output a class-based pre-calculated grid (col-xs-x,col-sm-x etc)
	);


// ----------------------------------------------------------
// =Misc
// ----------------------------------------------------------

// Padding for the content area, to separate from the #hero
$content-padding-top: 50px;
$content-padding-bottom: 50px;

$container-max-width: 113rem;
$container-small-max-width: 74rem;
