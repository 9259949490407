.home-section {
	//@extend .container;
	text-align: center;
	padding: 3rem 0;
	overflow: hidden;
	font-family: $primary-font-stack;
	font-weight: 300;
	text-transform: uppercase;


	@include mq(md) {

	}

	@include mq(lg) {
		padding-top: 7rem;
		padding-bottom: 5.9rem;
	}

	.home-section__title{
		font-size: 1.5rem;
		width: 90%;
		line-height: 1.4rem;
		font-weight: 400;
		display: inline-block;

		@include mq(sm) {

			font-size: 2rem;
			line-height: 2rem;
			width: 30rem;
		}

		@include mq(md) {
			margin-top: 0;
			font-size: 2.2rem;
			line-height: 2.3rem;
			width: 36rem;
		}

		@include mq(lg){
			font-size: 3rem;
			width: 50rem;
			line-height: 3.2rem;
		}

	}



	.home-section__subtitle{
		padding-top: 0;
		width: 90%;
		color: #6d6d6d;
		font-size: 1.275rem;
		display: inline-block;
		text-transform: none;
		line-height: normal;
		margin-top: 0.5rem;

		p{
			font-family: $secondary-font-stack-light;
		}

		@include mq(sm) {
			width: 90%;
			padding-top: 0;
			font-size: 1.275rem;
		}

		@include mq(md) {

			font-size: 1.1rem;
			padding-top: .1rem;
		}

		@include mq(lg){

			font-size: 1.3rem;
		}

	}

	.home-section__listing-image-title{
		padding-top: 1rem;
		padding-bottom: .8rem;
		font-size: 1rem;
		font-weight: normal;

		@include mq(sm) {
			font-size: 1rem;
		}

		@include mq(md) {
			font-size: 1.5rem;
    	font-weight: normal;
			padding-top: 1.3rem;
		}

		@include mq(lg){
			padding-top: 2rem;
			padding-bottom: 1.2rem;
			font-size: 1.8rem;
		}

	}

	.home-section__listing-image-grid{

		text-align: center;
		text-transform: none;

		.image-grid{

			padding: 0;

			.image-grid__listing{

					margin: 0;
			}
		}

		a{
			font-family: $primary-font-stack;
		}

		.home-section__content{
			display: inline-block;
			width: 80%;

			.image-grid__item:first-child{
				padding-left: 0;
			}

			.image-grid__item:last-child{
				padding-right: 0;
			}

			.image-grid__item{

				padding-left: 0;
				padding-right: 0;
				max-width: 24.4rem;
				max-height: 16.4rem;
				overflow: hidden;
				margin-bottom: 0.5rem;

				@include mq(sm) {
					padding-left: 0.3rem;
					padding-right: 0.3rem;
					margin-bottom: 0;
				}
				
				@include mq(md) {
					padding-left: 0.6rem;
					padding-right: 0.6rem;
				}

				.image-grid__item-title{

					bottom: auto;
					font-size: 1.3rem;
					font-weight: normal;
					padding: 20px 20px 30px 25px;

					@include mq(sm){
						font-size: 1rem;
						padding: 1rem .7rem .7rem 1.2rem;
					}

					@include mq(md){
						font-size: 1.2rem;
						padding: 1rem 1rem 1rem 1.5rem;
					}

					@include mq(lg){
						font-size: 1.3rem;
						padding: 1.4rem 2.4rem 1.4rem 2.4rem;
					}

				}

				.image-grid__item-image{
					height: 100%;
					min-height: 14rem;

					img{
						width: auto;
						transform: scale(1.1);
						height: 100%;
						max-width: none;
					}

					@include mq(sm){
						height: auto;
						width: 100%;
						min-height: 10rem;

						img{
							transform: scale(1.3);
						}
					}

					@include mq(md){
						min-height: 17rem;
					}
				}
			}

			@include mq(xs){
				width: 62%;
			}

			@include mq(sm){
				width: 85%;
			}

			@include mq(lg){
				width: 100%;
			}

		}




	}


}

.home-section--events-container{

	background-color: #f5f5f5;
	padding: 3rem 0;
	text-align: center;
	height: 100%;

	@include mq(md){

		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	@include mq(lg){
		padding-top: 6rem;
		padding-bottom: 6.5rem;
	}

	.home-news{
		padding-top: 1.3rem;
		width: 100%;
		display: unset;

		a.no-styling{

			text-decoration: none;

			&:hover, &:active, &:visited {
				text-decoration: none;
			}
		}

		.news-item-list{
			display: none;
		}

		@include mq(sm) {
			display: inline-block;
			width: 90%;
			height: auto;
			margin-bottom: .1rem;

			.news-item-carousel{
				display: none;
			}

			.news-item-list{
				display: block;
			}
		}

		@include mq(md){
			padding-top: 1.3rem;
			width: 89%;
		}

		@include mq(lg){
			width: 100%;
			padding-top: 0.8rem;
		}

		.home-news__view-more-section{

				margin-top: 1rem;


				a{
					width: 90%;
					font-size: 1rem;
    			font-weight: normal;
					line-height: normal;
					padding: .8rem 0;
				}

				@include mq(sm){
					a{
						width: 24%;
					}
				}

				@include mq(md){

					a{
						width: auto;
						min-height: 0;
						font-size: 1rem;
						padding: .8rem 2rem;
					}
				}

				@include mq(lg){

					margin-top: 1.8rem;

					a{

						min-height: 0;
						font-size: 1rem;
						padding: .8rem 2rem;
					}
				}
		}
	}

	.home-section__title{
		font-size: 1.5rem;
		text-transform: uppercase;
		margin-bottom: 1.3rem;

		@include mq(sm){
				font-size: 2rem;
		}

		@include mq(md){
			font-size: 2.2rem;
		}

		@include mq(lg){
			font-size: 3rem;
		}

	}

	.home-news__item{

		display: inline-block;
		border: none;
		padding: 0 1rem .25rem 0;

		@include mq(sm){
			width: 100%;
			padding: 0 0 0.5rem 0;
		}

		.home-news__item-date-wrapper{
			width: fit-content;
			display: inline-block;

		}

		.home-news__item-date{

			display: inline-block;
			float: left;
			width: 30%;
			background-color: #ffcd03;
			margin: 0;
			font-family: $primary-font-stack;
			font-weight: 100;
			text-align: center;
			padding: 1.6rem 0 0 0;
			height: 7rem;

			@include mq(sm){
				width: 18%;
				height: 9rem;
				padding: 1.5rem 0 1.5rem 0;
			}

			@include mq(md){

			}

			@include mq(lg){
				height: 100%;
				min-height: 14rem;
				max-height: 14.2rem;
			}

			.home-news__item-day-month{

				text-align: left;
				font-size: 1.6rem;
				line-height: 1.4rem;
				text-transform: uppercase;

				@include mq(sm){
					font-size: 2.2rem;
					line-height: 2.2rem;
				}

				@include mq(md){
					font-size: 2.5rem;
					line-height: 2.4rem;
				}

				@include mq(lg){
					font-size: 3.8rem;
					line-height: 3.6rem;
				}

			}

			.home-news__item-year{

				padding-top: 0;
				text-align: left;
				font-size: .8rem;

				@include mq(sm){
					padding-top: 0.15rem;
					font-size: 1.1rem;
				}

				@include mq(md){
					padding-top: 0;
					font-size: 1.2rem;
				}

				@include mq(lg){
					padding-top: .4rem;
					font-size: 1.6rem;
				}

			}
		}

		.home-news__item-image{

			display: inline-block;
			float: left;
			width: 70%;
			background-color: #ca6b96;
			margin: 0;
			background-repeat: no-repeat !important;
			background-position: center center !important;
			background-size: cover !important;
			height: 7rem;

			@include mq(sm){
				width: 36%;
				height: 9rem;
			}

			@include mq(md){
				height: 9rem;
			}

			@include mq(lg){
				height: 100%;
				min-height: 14rem;
				max-height: 14.2rem;
			}
		}

		.home-news__item-description{

			display: inline-block;
			float: left;
			width: 100%;
			background-color: #ffffff;
			margin: 0;
			height: 12rem;
			text-align: left;
			padding: 1.5rem 1rem 0 1rem;
			position: relative;

			.home-news__item-description-title{
				font-size: 1.2rem;
				font-weight: normal;
				text-transform: capitalize;
				width: 100%;
			}

			.home-news__item-description-content{
				font-size: 1rem;
				line-height: 1.3rem;
				text-align: left;
				overflow: hidden;
				width: 100%;
				color: #6d6d6d;
				font-weight: 100;
				height: 7.5rem;
				font-family: $secondary-font-stack-light;
				margin-top: .5rem;
			}

			.home-news__item-description-button{

				display: none;

				// a {
				// 	text-decoration: none;
				// 	font-family: $primary-font-stack;
				// 	font-size: 0.5rem;
				// 	min-height: 0;
				// 	padding: .5rem 1rem;
				// }
			}


			@include mq(sm){
				height: 9rem;
				width: 46%;

				.home-news__item-description-title{
					font-size: 1rem;
					font-weight: normal;
					text-transform: capitalize;
					width: 75%;
					overflow: hidden;
				}

				.home-news__item-description-content{
					font-size: .9rem;
					line-height: 1rem;
    			height: 3rem;
			    text-align: left;
					overflow: hidden;
					width: 98%;
					color: #6d6d6d;
					font-weight: 100;
					font-family: $secondary-font-stack-light;

				}

				.home-news__item-description-button{
					text-transform: uppercase;
					font-size: .6rem;
					font-weight: 100;
					position: absolute;
					bottom: 0;
					right: 0;
					display: block;
					// background: #000000;
					// padding: .45rem 1rem .45rem 1rem;

					a{
						font-size: .8rem;
				    	line-height: .7rem;
				    	padding: .8rem 1.8rem;
						min-height: 0;
						font-weight: 300;
					}
				}
			}

			@include mq(md){
				height: 9rem;
				padding: 1.6rem;

				.home-news__item-description-title{
						font-size: .8rem;
				}

				.home-news__item-description-content{
					line-height: 1rem;
    				height: 3rem;
				}

				.home-news__item-description-button{

					// min-height: 0;
					// font-size: .5rem;
					// padding: .3rem .8rem .3rem .8rem;
				}

			}

			@include mq(lg){
				height: 100%;
				min-height: 14rem;
				max-height: 14.2rem;
				padding-top: 1rem;

				.home-news__item-description-title{
						font-size: 1.4rem;
				}

				.home-news__item-description-content{
					line-height: 1.5rem;
    				height: 4.5rem;
					font-size: 1rem;
				}

				.home-news__item-description-button{

					// font-size: .7rem;
					// padding: .5rem 1.3rem .5rem 1.3rem;
				}

			}

		}
	}
}

.home-section--partner-container{

	padding: 3rem 0;
	text-align: center;

	.container--small{
		padding: 0 1.6rem;
	}

	.home-section__title{

		font-size: 1.4rem;
		text-transform: uppercase;
	}

	@include mq(sm){

		margin-top: 0;

		.home-section__title{
			font-size: 2rem;
		}

	}

	@include mq(md){

		margin: 3rem 0;
		padding: 0;

		.home-section__title{
			font-size: 2.2rem;
		}
	}

	@include mq(lg){

		margin: 6.2rem 0 4.4rem 0;

		.home-section__title{
			font-size: 3rem;
		}
	}



	.home-section--partner-wrapper{
		width: 80%;
		display: inline-block;

		.caroufredsel_wrapper{
			width: auto;
		}

		.sponsors__control--prev{
				height: 1.8rem;
		}

		.sponsors__control--next{
				height: 1.8rem;
		}

		@include mq(sm){
			width: 84%;
		}

		@include mq(md){
			margin-top: 0;
			margin-bottom: 0;
		}

		@include mq(lg){
			margin-top: .5rem;
			margin-bottom: 0;
			width: 100%;
		}

	}

}


//Overriding slick styling
//for partner section
.partners-carousel{

	margin: 2rem 0 0 0;

	.partner-carousel__item{
		padding: 0;

		img{
			width: 100%;
			max-width: 4rem;
		}
	}

	.slick-prev::before{
		content: "";
	}

	.slick-prev{
		background: url("#{$icons}arrow-prev.png") no-repeat center center;
		background-size: contain;
		height: 2.6rem;
		width: 1.6rem;
	}

	.slick-next::before{
		content: "";
	}

	.slick-next{
		background: url("#{$icons}arrow-next.png") no-repeat center center;
		background-size: contain;
		height: 2.6rem;
		width: 1.6rem;
	}

	.slick-slide{
		text-align: center;

		img{
			display: inline-block;
		}
	}


}
