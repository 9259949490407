.m__completion-status {
    max-width: 1200px !important;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 2rem;
    margin: 0 auto;
    padding: 2rem 0 4rem;

    @include mq(md) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 4rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    h4 {
        font-size: 1rem;
    }

    h4.m__cs--mt {
        margin-top: 3rem;
    }

    .m__cs--ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;

        .m__cs--li {
            display: flex;
            align-items: flex-start;
            gap: 2rem;
            margin-bottom: 1rem;
            font-size: 1.25rem;

            div:first-child {
                flex: 1 1 auto;
            }

            .m__cs--fail,
            .m__cs--pass {
                width: 2rem;
                height: 2rem;
                border-radius: 100%;
                background-color: antiquewhite;
                display: block;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .m__cs--fail {
                background-color: #e3485a;

                svg {
                    width: 1rem;
                    height: 1rem;
                }
            }

            .m__cs--pass {
                background-color: #549e3e;

                svg {
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }
        }
    }
}
