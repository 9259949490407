/*
	jQuery.mmenu panels CSS
*/
@import "inc/variables";


//	Animations
.mm-menu.mm-horizontal > .mm-panel
{
	@include mm_vendor-prefix( "transition", left $mm_transitionDuration $mm_transitionFunction );
}


//	Generic classes
.mm-menu .mm-hidden
{
	display: none;
}


//	Container
.mm-wrapper
{
	overflow-x: hidden;
	position: relative;
}

//	Menu
.mm-menu
{
	background: $mm_baseBg;
	display: block;
	overflow: hidden;
	width: 100%;
	height: 100%;
	padding: 0;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 0;

	> .mm-panel
	{
		@include mm_vendor-prefix( "box-sizing", border-box );

		background: inherit;
	
		-webkit-overflow-scrolling: touch;
		overflow: scroll;
		overflow-x: hidden;
		overflow-y: auto;
	
		width: 100%;
		height: 100%;
		padding: $mm_padding * 2;
		position: absolute;
		top: 0;
		left: 100%;
		z-index: 0;
		
		&.mm-opened
		{
			left: 0%;
		}
		&.mm-subopened
		{
			left: -$mm_subpanelOffset;
		}
		&.mm-highest
		{
			z-index: 1;
		}
	}

	//	Lists
	.mm-list
	{
		padding: ( $mm_btnSize / 2 ) 0;
	}
	> .mm-list
	{
		padding: ( $mm_btnSize / 2 ) 0 $mm_btnSize 0;
	}
}
.mm-panel > .mm-list
{
	margin-left: -( $mm_padding * 2 );
	margin-right: -( $mm_padding * 2 );
	
	&:first-child
	{
		padding-top: 0;
	}
}

.mm-list,
.mm-list > li
{
	list-style: none;
	display: block;
	padding: 0;
	margin: 0;
}
.mm-list
{
	font: inherit;

	a,
	a:hover
	{
		text-decoration: none;
	}

	> li
	{
		position: relative;

		> a {
			background: map-get($mobile-menu, background);
			border-bottom: 1px solid map-get($mobile-menu, border-colour);
			@include mm_vendor-prefix( "box-sizing", border-box );
		}

		> a,
		> span
		{
			@include mm_ellipsis;
			color: inherit;
			line-height: $mm_btnSize - ( $mm_padding * 2 );
			display: block;
			padding: $mm_padding $mm_padding $mm_padding ( $mm_padding * 2 );
			margin: 0;

			&:hover {
				background: map-get($mobile-menu, background-hover);
				color: map-get($mobile-menu, text-hover);
			}
		}
	}

	> li:not(.mm-label):not(.mm-search):not(.mm-noresults)
	{

		
		
		&:after
		{
			width: auto;
			position: relative;
			left: auto;
		}
	}

	// subopen/close
	a.mm-subopen
	{
		@include mm_border-left;

		width: $mm_subopenWidth;
		height: 100%;
		padding: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 2;

		&:hover:before {
			border-color: map-get($mobile-menu, border-colour);
		}
		
		&.mm-fullsubopen
		{
			width: 100%;
			
			&:before
			{
				border-left: none;
			}
		}

		+ a,
		+ span
		{
			padding-right: ( $mm_padding / 2 );
			margin-right: $mm_subopenWidth;
		}
	}

	> li.mm-selected
	{
		> a.mm-subopen
		{
			background: transparent;
		}
		> a.mm-fullsubopen
		{
			+ a,
			+ span
			{
				padding-right: $mm_btnSize + ( $mm_padding / 2 );
				margin-right: 0;
			}
		}
	}
	
	a.mm-subclose
	{
		text-indent: $mm_btnSize - ( $mm_padding * 2 );
	}

	// Labels
	$labelSize: ( $mm_btnSize / 2 ) + ( $mm_padding / 2 );
	> li.mm-label
	{
		@include mm_ellipsis;
		text-transform: uppercase;
		text-indent: $mm_padding * 2;
		line-height: $labelSize;
		padding-right: $mm_padding / 2;
	}

	// Spacers
	> li.mm-spacer
	{
		padding-top: $mm_btnSize;
		
		&.mm-label
		{
			padding-top: $labelSize;
		}
	}

	// Border colour
	a.mm-subopen:before {
		&:hover:before {
			border-color: map-get($mobile-menu, border-colour);
		}
	}

	// Arrows
	a.mm-subopen:after,
	a.mm-subclose:before
	{
		@include mm_arrow;
		border-color: map-get($mobile-menu, arrow-colour);
	}
	a.mm-subopen:hover:after,
	a.mm-subclose:hover:before {
		border-color: map-get($mobile-menu, arrow-colour-hover);
	}
	a.mm-subopen:after
	{
		@include mm_arrow-next;
		right: 17px;
	}
	a.mm-subclose:before
	{
		@include mm_arrow-prev;
		left: $mm_padding * 2;
	}
	.mm-subtitle a.mm-subclose:before {
		border-color: map-get($mobile-menu, selected-arrow-colour);
	}
	.mm-subtitle a.mm-subclose:hover:before {
		border-color: map-get($mobile-menu, selected-arrow-colour-hover);
	}
}

// vertical submenu
.mm-menu.mm-vertical .mm-list
{
	.mm-panel
	{
		display: none;
		padding: $mm_padding 0 $mm_padding $mm_padding;
		
		li:last-child:after
		{
			border-color: transparent;
		}
	}
	li.mm-opened > .mm-panel
	{
		display: block;
	}
	> li.mm-opened > a.mm-subopen
	{
		height: $mm_btnSize;
		&:after
		{
			@include mm_vendor-prefix( "transform", rotate( 45deg ) );
			top: 16px;
			right: 16px;
		}
	}
}

@include mm_colors;