@font-face {
    font-family: 'FuturTBoo';
    src: url('/fonts/futurat/FuturaT-Book.woff2') format('woff2'),
        url('/fonts/futurat/FuturaT-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturTLig';
    src: url('/fonts/futurat/FuturaT-Light.ttf') format('ttf'),
        url('/fonts/futurat/FuturaT-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}
