.calendar{

	&__list-view.is-hidden{
		display: block !important;
	}

	@include mq(md){
		&__list-view.is-hidden{
			display: none !important;
		}
	}

	&__wrapper{
		display: none;
		padding: 20px 40px;

		@include mq(md){
			display: block;
		}

		@include mq(lg){
			padding: 0 2rem;
		}
	}

	&__header{
		padding: 4rem 0 0 2rem;
		margin: 0 0 2rem 0;

		@include mq(sm){
			margin: 0;
			padding: 5rem 0 0 2rem;
		}
	}

	&__current-date{
		font-size: 1.5rem;
		text-transform: uppercase;

		@include mq(sm){
			font-size: 2.5rem;
			line-height: 2rem;
		}
	}

	&__toolbar{

		display: inline-block;

		ul{
			li{
				font-weight: 200;
				width: auto;

				label{
					font-size: .9rem;
				}

				input{
					vertical-align: top;
					border: 1px solid $yellow;
					width: 1.7rem;
					height: .8rem;
					margin-top: .7rem;

					&:checked::after{
						position: absolute;
						width: 1.6rem;
				    height: .8rem;
				    margin-top: -0.4rem;
						margin-left: -0.4rem;
					}
				}
			}

			li.calendar__filter-item--text{
				font-weight: 400;
				color: $black;
			}
		}

		#fullcalendar-filter{
				display: inline-block;
				width: auto;

				ul{
					li:first-child{
							width: 100%;
					}

					li{
						width: 50%;
						padding-left: 0;
					}
				}

				@include mq(sm){
					ul{

						li{
							width: 33.3%;
							padding-left: 0;
						}
					}
				}

				@include mq(md){
					ul{

						li:first-child{
							width: auto;
						}

						li{
							width: auto;
							padding-left: 0;
						}
					}
				}

				@include mq(lg){
					ul{
						padding-top: 1rem;
					}
				}
		}

		&--right{
			float: left;
			text-align: right;
			display: inline-block;
			padding-right: 5rem;

			@include mq(sm){

				float: right;
				padding-right: .5rem;
			}

			@include mq(md){
				padding-right: 2.5rem;
			}

			@include mq(lg){
				padding-top: 1.3rem;
			}
		}

		&--list-view{
			display: none;
			float: left;
			padding-top: 0.1rem;
			margin:0 .5rem;

			@include mq(md){
				display: inline-block;
			}
		}

		&--grid-view{
			display: none;
			float: left;
			padding-top: 0.1rem;
			margin:0 .5rem;

			@include mq(md){
				display: inline-block;
			}
		}

		&--previous-button{
			display: inline-block;
			vertical-align: text-bottom;
			float: left;
			margin: 0 1rem 0 0;

			@include mq(sm){
				margin: 0 .5rem;
			}

			&::before{
				content: '‹';
				background-color: $yellow;
				font-size: 1.5rem;
				font-weight: 200;
				height: auto;
				padding: 0 .8rem;
			}
		}

		&--next-button{
			display: inline-block;
			vertical-align: text-bottom;
			float: left;

			&::before{
				content: '›';
				background-color: $yellow;
				font-size: 1.5rem;
				font-weight: 200;
				height: auto;
				padding: 0 .8rem;
			}
		}


		@include mq(md){
			width: 100%;
		}
	}

	&__list-view{
		padding: 0 .5rem;
		font-size: .8rem;

		&--no-events-found{
			margin-left: 1.5rem;
		}

		@include mq(sm){
			padding: 2.5rem 0.5rem;
			font-size: 1.375rem;
		}

		@include mq(md){
			padding: 0 2.5rem;
			font-size: 1.375rem;

			&--no-events-found{
				margin-top: 3rem;
				margin-left: 0;
			}
		}

		.ui-accordion{
				&-header{
					padding: 0;
					font-family: $primary-font-stack;
					font-weight: 200;
					min-width: 1rem;

					&--date{
						float: left;
						background-color: $yellow;
						color: $black;
						padding: 1rem .5rem;
						text-transform: uppercase;
					}

					&--title{
						display: inline-block;
						padding: 1rem .5rem;
					}

					&::after{
						padding: 1rem .5rem;
						font-size: 1rem;
					}

					&-active{

						&::after{
							padding: 1rem .5rem;
							font-size: 1rem;
						}
					}

					@include mq(sm){
						&--date{
							font-size: 1rem;
						}

						&--title{
							font-size: 1rem;
						}

					}

					@include mq(md){
						min-width: 3rem;

						&--date{
							min-width: 5rem;
						}
					}

					@include mq(lg){

						&--date{
							font-size: 1.3rem;
							padding: 1rem 2rem;
						}

						&--title{
							font-size: 1.3rem;
							padding: 1rem 2rem;
						}
					}


				}

				&-content{
					min-width: 1rem;

					@include mq(sm){
						min-width: 3rem;
					}
				}

				&-bottom-buttons{
					padding: 2rem;

					a{
						font-size: .8rem;

						@include mq(sm){
							font-size: .8rem;
						}
					}

					.primary-button{
						margin-left: 0;
						margin-top: .5rem;

						@include mq(sm){
							margin-left: .5.5rem;
							margin-top: 0;
						}
					}
				}


		}
	}
}

.calendar__toggle{
	display: none;
	width: 65px;
	height: 65px;
	background: url('/img/icons/calendar-1.png') center center no-repeat palette(brand-colour);
	background-size: 41px 41px;
	cursor: pointer;
	position: absolute;
	right: 20px;

	@include mq(sm, max){
		display: none!important;
	}

}

.calendar__filter{
	padding: 20px 40px;
}

.calendar__title{
	margin: 30px 0 0;
}

.calendar__filter-list{
	@extend %list-reset;
	@extend %clearfix;
}

.calendar__filter-item{
	@include grid-col(1,2);
	margin-bottom: 15px;
	@include mq(lg){
		@include grid-col(1,3);
	}

	@include mq(1500px){
		@include grid-col(1,5);
	}
}

.calendar__filter-label{
	font-size: 18px;
}

.calendar__filter-input{
	margin-right: 10px;
}

.calendar__wrapper{
	// padding: 20px 40px;
}

.template-calendar .calendar--cotntent{
	display: block;

	@include mq(sm){
		display: block;
	}
}


// ----------------------------------------------------
// Fullcalendar styles
// ----------------------------------------------------
#fullcalendar{
	&.fc {

		.fc-toolbar{
			display: none;
		}

		button{
			height: auto;
			width: auto;
		}

		.fc-today-button{
			display: none;
		}

		.fc-event{
			background: $yellow;
			border: 0;
			color: $black;
			@include border-radius(50px);
		}

		.fc-day-grid-event{
			padding: 5px 15px;
		}

		.fc-body td{
			border: 0;

			div a{
				font-size: 14px;
				display: block;
				text-align: right;
			}

			.fc-content{
				text-align: left;
				font-size: 16px;
			}
		}
		.fc-body thead td{
			border: 0;
			border-top: 1px solid $yellow;
			padding-top:10px;
			font-weight: normal;
			font-family: font(medium);
		}

		.fc-head{

			.fc-row.fc-widget-header{
				border: none;
			}
		}


		.fc-head .fc-head-container{
			border: 0;
			padding-right: 0;

			th{
				border: 0;
				padding: 10px 5px;
			}


		}



		.fc-body td.fc-widget-content{
			border-bottom: 1px solid $yellow;
			padding-right: .05rem;

			.fc-scroller{
				height: auto!important;
			}
		}
	}

	// ---------------------------------------------------------
	// qtip styles
	// --------------------------------------------------------

	#qtip-fullcalendar{

		&.qtip-default{
			background: $black;
			border: 1px solid $black;
		}

		.qtip-content{
			background: $black;
			border: 1px solid $black;
			color: white;
			padding: 20px;
		}

		.qtip-close{
			padding: 5px;
			background: transparent;
			color: $yellow;
			border: 0;
			font-size: 14px;
			top: 0;
			right: 0;

		}

		.toggleEvents{
			text-decoration: none;

			h3{
				text-transform: uppercase;
				color: $yellow;
				font-size: 24px;
			}
		}

		.event-row{
			position: relative;
			padding-left: 45px;
			margin-bottom: 10px;
		}

		.event-title{
			font-size: 16px;
			// color: $yellow;
			font-family: font(medium);
			position: absolute;
			left: 0;
		}

		.event-info{
			font-size: 14px;
			line-height: 1.2;
		}

		.event-button{
			display: inline-block;
			padding: 5px 10px;
			text-decoration: none;
			background: $yellow;
			margin: -2px 10px;
			// @include border-radius(20px);
		}


	}

}
