// ----------------------------------------------------------
// =Form elements
// ----------------------------------------------------------

$form-element-border-colour: palette(primary-border-colour);
$form-element-border-colour-hover: palette(primary-border-colour-hover);
$form-element-border-colour-active: palette(primary-border-colour-active);

$form-element-border: 1px solid $form-element-border-colour;
$form-element-border-radius: 0;

$form-element-background: palette(primary-background);
$form-element-background-hover: darken($form-element-background, 10%);
$form-element-background-active: darken($form-element-background, 10%);

$form-element-text: palette(primary-text);
$form-element-padding: 14px 15px 6px;
$form-element-height: 50px;
$form-element-margin: 0 0 10px 0;

// Reset inputs and textareas to remove device styling
input, textarea, select {

	border: $form-element-border;
	border-radius: $form-element-border-radius;
	background: $form-element-background;
	box-shadow: none;
	padding: $form-element-padding;
	width: 100%;
	height: $form-element-height;
	font-family: font(light);
	font-size: font(size-xxs);
	line-height: 1.5;

	// Remove default box shadows
	&:focus,
	&:invalid {
		box-shadow: none;
		outline: none;
	}

	@include mq(sm){
		width: auto;
	}
	
}

// input, textarea {
// 	&:disabled,
// 	&:read-only {
// 		background-color: $almost-white;
// 	}
// }

textarea {
	height: auto;
	min-height: 120px;
}

// Radio/Checkboxes
input[type="checkbox"],
input[type="radio"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width: 30px;
	height: 30px;
	padding: 6px;
	position: relative;
	cursor: pointer;
	vertical-align: middle;

	&:checked:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background: $yellow;
	}
}

input[type="radio"] {
	@include border-radius(50%);

	&:checked:after {
		@include border-radius(50%);
	}
}

select {
	@extend %hide-select-arrow;
	font-family: font(default);
	border: $form-element-border;
	border-radius: $form-element-border-radius;
	background: $form-element-background url('#{$icons}select-arrow-yellow.png') right center no-repeat;
	padding: $form-element-padding;
	padding-right: 35px;
	cursor: pointer;
}


label.in-field-label {		
	@include transition(all ease-in-out 300ms);
	position: absolute;
	left: 16px;
	font-family: font(light);
	font-size: font(size-xxs);
	top: 14px;
	color: $dark-grey;

	&.active,
	select ~ &,
	input:focus ~ &,
	input.has-input ~ &,
	textarea:focus ~ &,
	textarea.has-input ~ & {
		top: 5px;
		font-size: 12px;
		color: $black;
		padding-right: 5px;
		line-height: 1;
	}

	&.static,
	&.static.active {	
		top: 17px;
		font-size: font(size-xxs);
		color: $dark-grey;
		padding-right: 0;
		line-height: 1.3;
		
		select ~ &,
		input:focus ~ &,
		input.has-input ~ &,
		textarea:focus ~ &,
		textarea.has-input ~ & {
			top: 17px;
			font-size: font(size-xxs);
			color: $dark-grey;
			padding-right: 0;
			line-height: 1.3;
		}		
	}
}

// iCMS Forms
.cmsform {
	.field_contain {
		margin-bottom: $vertical-margins;
	}

		.field_name {
			padding: 0;
			float: none;
			text-align: left;
			font-size: font(size-xs);
			line-height: 1.5;
			width: auto;
			overflow: hidden;

			.required {
				color: $yellow;
				font-weight: bold;
				margin-left: 5px;
			}

			label {
				float: left;
			}
		}

		.field_input {
			margin: 0;

			input, select, textarea {
				width: 100%;
				max-width: 360px;
			}

			// Radio/Checkboxes
			.checkbox_item,
			.radio_item {
				width: 100%;
				max-width: 360px;
				margin-bottom: 5px;
				font-size: font(size-xs);
				line-height: 1.5;
			}

				input[type="checkbox"],
				input[type="radio"] {
					width: 30px;
					float: left;

					+ span {
						vertical-align: middle;
						margin-left: 40px;
						display: block;
					}
				}

			// Date Fields
			select[id$="_d"],
			select[id$="_m"] {
				width: 25%;
				max-width: 100px;
				padding-left: 10px; // fix text cut off

				@include mq(xs){
					padding: $form-element-padding; // reset back to normal
				}
			}
			select[id$="_y"] {
				width: calc(50% - (7px * 2)); // 7px = spacing btw selects
				max-width: 150px;
			}
		}

		.supporting {
			margin: 3px 0 0 0;
			padding: 0;
			max-width: 360px;
			font-size: font(size-xxs);
			line-height: 1.5;
		}

	// Security Check
	.captureform2 {
		> .field_name {
			display: inline-block;

			.required {
				float: right;
			}
		}
	}

	.form_submit {
		width: 100%;
		max-width: 360px;
		padding-left: 0;
		text-align: left;
	}

	.option_contain {
		width: 100%; 
	}
}