.home-section--footer {

	text-align: center;
	background-color: $yellow;
	padding: 3rem 0;


	@include mq(sm){
		background: url('/img/backgrounds/footer-background.png');
		background-position: center;
		background-size: cover;
		padding-top: 4rem;

		.home-section--footer-mobile-title{
			display: none;
		}
	}

	@include mq(md){
		padding-top: 3.6rem;


	}

	@include mq(lg){
		padding-top: 6.2rem;
	}

	.home-section--footer-mobile-title{
		text-transform: uppercase;
		font-size: 1.5rem;

	}


	.home-section--footer-content-wrapper{
		display: inline-block;
		width: 85%;

		.home-section--footer-cta{
			height: 18rem;
			border-bottom: .025rem solid #282828;
		}

		@include mq(sm){
			width: 90%;

			.home-section--footer-cta{
				height: 17.4rem;
			}
		}

		@include mq(md){
			.home-section--footer-cta{
				height: 22rem;
			}
		}

		@include mq(lg){
			width: 100%;
			.home-section--footer-cta{
				height: 28.5rem;
			}
		}

		.footer-cta--left{
				display: none;
				float: left;
				width: 35%;
				text-align: left;

				.footer-cta--left-circle{

					background: #000000;
					border-radius: 50%;
					height: 16rem;
					width: 16rem;
					text-align: center;
					padding-top: 2.6rem;

					a{
						text-decoration: none;
						font-size: 2.5rem;
						line-height: 2.6rem;
						text-transform: uppercase;
						text-align: left;
						width: 50%;
						display: inline-block;
						color: #fff;
						font-family: $primary-font-stack;
						font-weight: 300;
						padding-left: .5rem;
					}

					&::after{
					 	top: -23%;
						content: ' ';
						display: inline-block;
						position: relative;
						right: -65px;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 30px 0 30px 30px;
						border-color: transparent transparent transparent #000000;
					}

					@include mq(sm){
						height: 14rem;
				    	width: 14rem;
						padding-top: 2.2rem;
						padding-left: 1rem;

						a{
							font-size: 2.8rem;
    						line-height: 2.5rem;
							font-weight: 300;
						}

						&::after{
							top: -28%;
							right: -50px;

						}


					}

					@include mq(md){
						height: 18rem;
						width: 18rem;
						padding-left: 2rem;
						padding-top: 3.8rem;

						a{
							font-size: 3rem;
							line-height: 2.8rem;
						}

						&::after{
							top: -25%;
							right: -65px;
						}
					}

					@include mq(lg){
						height: 23rem;
						width: 23rem;
						padding-left: 1rem;

						a{
							font-size: 4rem;
							font-weight: 400;
							line-height: 3.8rem;
						}

						&::after{
							right: -96px;
							top: -26%;
						}
					}

				}

				@include mq(sm){
					display: inline-block;
				}
		}

		.footer-cta--right{
				display: inline-block;
				float: left;
				width: auto;
				padding-top: 1.35rem;

				ul{
					text-align: left;
					list-style: none;
					padding: 0;

					li{
						padding-bottom: .8rem;

					}

					li a{
						text-decoration: none;
						font-family: $primary-font-stack;
						font-weight: 200;
						font-size: 1rem;
					}
				}

				.active{
					a{
						font-weight: 200;
					}
				}

				@include mq(sm){
					width: 60%;
					padding: 0;
					padding-left: 1rem;

					ul{

						li{
							padding-bottom: 0;

						}

						li a{
							font-size: 1rem;
							line-height: 2.8rem;

							&:hover{
								font-weight: 400;
							}
						}
					}

					.active{
						a{
							font-weight: normal;
						}
					}
				}

				@include mq(md){
					width: 60%;
					padding-top: 0.85rem;

					ul{

						padding-left: 1rem;

						li{
							padding-bottom: 0;

						}

						li a{
							font-size: 1.2rem;
							line-height: 3.4rem;
						}
					}
				}

				@include mq(lg){

					ul{

						padding-left: 1.5rem;

						li a{
							font-size: 1.5rem;
							line-height: 4.4rem;
						}
					}
				}

		}

		.home-section--footer-newsletter{

			padding-top: 3rem;
			text-align: left;
			text-transform: uppercase;

			.footer-newsletter--title{
				font-size: 1.5rem;
			}

			.footer-newsletter--subtitle{
				font-family: $secondary-font-stack-light;
				text-transform: none;
				font-size: 1.275rem;
			}

			.footer-newsletter--form{
				padding-top: 1rem;
				height: auto;

				form{
					display: flex;
					flex-wrap: wrap;
				}

				label{
					text-transform: capitalize;
					font-size: .8rem;
					top:.5rem;
				}

				input{
					font-size: 1rem;
					border: none;
					height: 2.925rem;
					padding: 0 0 0 .8rem;
					width: 100%;
				}

				input::placeholder{

					font-family: $primary-font-stack;
					font-weight: 200;
					font-size: 1rem;
				}

				.footer__subscribe-container{
					float: none;
				}

				.footer__subscribe-input-wrapper{
						display: inline-block;
						width: 100%;
						line-height: 0;
						flex: 1 1 100%;
				}

				.footer__subscribe-button-wrapper{
					width: 100%;
					float: right;
					line-height: 0;

					input{
						font-family: $primary-font-stack;
						font-weight: normal;
						font-size: 1.1rem;
						width: 100%;
						float: left;
						padding: .8rem 0;
						min-height: 0;
						height: auto;
						line-height: normal;
						text-transform: uppercase;
					}
				}

				@include mq(sm){
					padding-top: 1.2rem;

					.footer-newsletter--title{
						font-size: 1.3rem;
					}

					input{
						font-family: $primary-font-stack;
						font-weight: 200;
					}

					.footer__subscribe-input-wrapper{
						flex: 1 1 40%;
					}

					.footer__subscribe-button-wrapper{
						flex: 1 1 20%;

						input{
							width: 100%;
						}
					}


				}

				@include mq(md){
					padding-top: .3rem;
					height: 3rem;

					.footer-newsletter--title{
						font-size: 1.8rem;
					}

					input{
						height: 2.8rem;
					}

					input::placeholder{
						font-size: 1.125rem;
					}

					.footer__subscribe-input-wrapper{
						width: 40%;
					}

					.footer__subscribe-button-wrapper{
						width: fit-content;
						width: auto;

						input{
							padding: .8rem 2rem;
							font-size: 1rem;
						}
					}
				}

				@include mq(lg){
					padding-top: .8rem;
					height: 4.5rem;

						input{
							height: 2.8rem;
						}

						input::placeholder{
							font-size: 1rem;
							padding-left: .3rem;
						}

						.footer__subscribe-input-wrapper{
							flex: 1 1 40%;
							padding-right: 0.3rem;
						}

						.footer__subscribe-button-wrapper{

							input{
								padding: .8rem 2.3rem;
								margin-top: 0;
								font-size: 1rem;
							}
						}
				}

			}

			@include mq(sm){
					.footer-newsletter--title{
						font-size: 1.3rem;
					}

			}

			@include mq(md){
				padding-top: 1rem;

				.footer-newsletter--title{
					font-size: 1.8rem;
				}

			}

			@include mq(lg){
				padding-top: 1.6rem;

				.footer-newsletter--title{
					font-size: 1.8rem;
				}

				.footer-newsletter--subtitle{
					font-size: 1.3rem;
				}
			}

		}

		.footer-section--links{

			height: 4rem;

			@include mq(sm){
				margin: 1.5rem 0 0 0;
			}

			.footer-section--navigation{

				left: 0;
				width: auto;
				display: inline-block;
				padding-left: 0;
				float: left;
				text-align: left;
				padding-top: 2rem;

				li a{
					font-family: $primary-font-stack;
					font-weight: normal;
					font-size: 1rem;
				}

				@include mq(sm){
					padding-top: 0;

					li{
						padding: 0 1.1rem 0 0;
					}

					li a{
						font-weight: 300;
						font-size: .8rem;
					}
				}

				@include mq(lg){

					li{
						padding: 0 1.2rem 0 0;
					}

					li a{
						font-size: 1rem;
					}
				}
			}

			.footer-section--social{

				display: inline-block;
				padding-left: 0.6rem;
				padding-top: .3rem;
				float: left;
				width: auto;
				text-align: left;
				list-style: none;

				li{
					display: inline-block;
					padding: 0 1rem 0 0;
				}

				.footer__social-item {
					@extend %list-item-reset;
					background-position: center center;
					background-repeat: no-repeat;
					display: inline-block;
					width: 1rem;
					height: 1rem;
					// margin: 0 15px;
					margin: 0 .5rem 0 .5rem;
					background-size: contain;
					vertical-align: top;

					a {
						display: block;
						width: 100%;
						height: 100%;
						text-indent: -9999px;
					}

					@include mq(1530px) {
						margin: 0 0 0 20px;
					};
				}

				.footer-section-item--twitter {
					@extend .footer__social-item;
					background-image: url("#{$icons}twitter.png");
					width: 1.8rem;
					height: 1.8rem;
				}
				.footer-section-item--facebook {
					@extend .footer__social-item;
					width: 1.5rem;
					height: 1.5rem;
					margin-top: .13rem;
					background-image: url("#{$icons}facebook.png")
				}
				.footer-section-item--linkedin {
					@extend .footer__social-item;
					width: 1.5rem;
					height: 1.5rem;
					margin-top: .07rem;
					background-image: url("#{$icons}linkedin.png")
				}

				@include mq(sm){

					li{
						padding: 0 1.4rem 0 0;
					}

					.footer-section-item--twitter {
						width: 1.1rem;
						height: 1.1rem;
						padding-top: 1rem;
    				margin-left: .8rem;
					}

					.footer-section-item--facebook {
						margin-left: 0;
						width: .9rem;
						height: .9rem;
					}

					.footer-section-item--linkedin {
						width: 1.3rem;
						height: 1.3rem;
						margin-left: 0;
					}


				}

				@include mq(lg){

					.footer-section-item--twitter {
						width: 1.3rem;
						height: 1.3rem;
						padding-top: 1rem;
    					margin-left: .8rem;
					}

					.footer-section-item--facebook {

						width: 1rem;
						height: 1.2rem;
						margin-left: .5rem;
						margin-right: 1rem;
					}

				}
			}

			.footer-section--brightlabs{

				padding-right: 0;
				float: right;
				width: 30%;
				text-align: right;
				margin-top: .5rem;
				font-weight: 300;

					a{
						font-family: $primary-font-stack;
						font-size: .7rem;
						text-align: right;
						text-decoration: none;
					}

					@include mq(sm){
						width: 10%;
						margin-top: 0;

						a{
							font-size: .7rem;
						}
					}

					@include mq(lg){
						padding-top: .2rem;

						a{
							font-size: .8rem;

						}
					}
			}

		}

	}

	.footer-acknowledgement-notice {
		font-size: 12.5px;
		font-weight: 400;
		text-align: left;
		width: 85%;
		margin: 0 auto;
		margin-top: 1rem;

		@include mq(sm){
			width: 90%;
			margin-top: 0.5rem;
		}

		@include mq(lg){
			width: 100%;
			margin-top: 0;
		}
	}

}

.footer__nav-item {
	@extend %list-item-reset;
	@include transition(background-color ease-in-out 300ms);
	display: inline-block;
	color: $black;
	height: auto;
	width: 50%;
	float: left;
	padding: 5px;

	@include mq(sm){
		float: none;
		width: auto;
		padding: 5px 15px;

	}
	@include mq(lg){
		padding: 0;
		margin-right: 20px;
	}

	@include mq(1500px){
		margin-right: 0;
	}

	> a {
		color: $black;
		padding: 0;
		text-decoration: none;
		text-transform: uppercase;
		font-size: font(size-xs);
		font-family: font(condensed);
	}
}

.footer__subscribe-container {
	position: relative;
	margin-bottom: 5px;

	@include mq(sm){
		float: left;
		margin-right: 11px;
	}
}

//Old SCSS
// .footer {
// 	// @extend %hide-on-print;
// 	// background: url('#{$backgrounds}yellow-grain.jpg') repeat;
// 	// color: $black;
// 	// width: 100%;
// 	// clear: both;
// 	// padding: 50px 0;
// 	// position: relative;
// }

	// .footer__inner {
	// 	max-width: 1500px;
	// 	margin: 0 auto;
	// }

	// .footer__content {
	// 	border-top: 1px solid $black;
	// 	border-bottom: 1px solid $black;
	// 	padding: 0;
	//
	// 	@include mq(lg) {
	// 		padding: 0 15px;
	// 		overflow: hidden;
	// 	}
	//
	// 	@include mq(1530px) {
	// 		padding: 0;
	// 	};
	// }

	// .footer__nav-list {
	// 	@extend %list-reset;
	// 	padding: 15px;
	//     text-align: center;
	//     margin-top: 15px;
	//
	// 	@include mq(lg){
	// 		text-align: left;
	// 		padding: 0;
	//    		margin-top: 40px;
	//    		float: left;
	// 	}
	// }

	// .footer__nav-item {
	// 	@extend %list-item-reset;
	// 	@include transition(background-color ease-in-out 300ms);
	// 	display: inline-block;
	// 	color: $black;
	// 	height: auto;
	// 	width: auto;
	// 	padding: 5px;
	//
	// 	@include mq(sm){
	// 		padding: 5px 15px;
	// 	}
	// 	@include mq(lg){
	// 		padding: 0;
	// 		margin-right: 20px;
	// 	}
	//
	// 	@include mq(1500px){
	// 		margin-right: 35px;
	// 	}
	//
	// 	> a {
	// 		color: $black;
	// 		padding: 0;
	// 		text-decoration: none;
	// 		text-transform: uppercase;
	// 		font-size: font(size-xs);
	// 		font-family: font(condensed);
	// 	}
	// }

	// .footer__subscribe {
	// 	padding: 15px;
	// 	max-width: 460px;
	// 	margin: 0 auto;
	//
	// 	@include mq(sm){
	// 		max-width: 710px;
	// 	}
	//
	// 	@include mq(lg){
	// 		padding: 24px 0 0 0;
	// 		margin: 0;
	// 		float: right;
	// 	}
	//
	// 	@include mq(1530px) {
	// 		float: left;
	// 		padding: 24px 0;
	// 		margin-left: 60px;
	// 	};
	// }

	// .footer__subscribe-input {
	//
	// 	@include mq(sm) {
	// 		width: 195px;
	// 	}
	// }
	//
	// input[type="submit"].footer__subscribe-submit {
	// 	@extend .secondary-button;
	// 	color: $yellow;
	// 	font-size: font(size-m);
	// }
	//
	// .footer__social {
	// 	@extend %list-reset;
	// 	margin: 10px 0 20px 0;
	// 	text-align: center;
	//
	// 	@include mq(lg) {
	// 		float: right;
	// 		clear: both;
	// 		margin-top: 20px;
	// 	};
	//
	// 	@include mq(1530px) {
	// 		clear: none;
	// 		margin-top: 40px;
	// 	};
	// }
	//
	// .footer__social-item {
	// 	@extend %list-item-reset;
	// 	background-position: center center;
	// 	background-repeat: no-repeat;
	// 	display: inline-block;
	// 	width: 20px;
	// 	height: 20px;
	// 	margin: 0 15px;
	//
	// 	a {
	// 		display: block;
	// 		width: 100%;
	// 		height: 100%;
	// 		text-indent: -9999px;
	// 	}
	//
	// 	@include mq(1530px) {
	// 		margin: 0 0 0 20px;
	// 	};
	// }
	// .footer__social-item--twitter {
	// 	@extend .footer__social-item;
	// 	background-image: url("#{$icons}twitter.png")
	// }
	// .footer__social-item--facebook {
	// 	@extend .footer__social-item;
	// 	background-image: url("#{$icons}facebook.png")
	// }
	// .footer__social-item--linkedin {
	// 	@extend .footer__social-item;
	// 	background-image: url("#{$icons}linkedin.png")
	// }
	//
	// .footer__brightlabs {
	// 	font-size: font(size-xxxxs);
	// 	font-family: font(bebas);
	// 	color: $black;
	// 	text-transform: uppercase;
	// 	width: 100%;
	// 	display: block;
	// 	text-align: center;
	// 	text-decoration: none;
	// 	margin-top: 10px;
	//
	// 	&:hover {
	// 		color: $black;
	// 	}
	//
	// 	@include mq(sm) {
	// 		width: auto;
	// 		float: right;
	// 		margin-right: 10px;
	// 	}
	//
	// 	@include mq(1530px) {
	// 		margin-right: 0;
	// 	};
	// }

	.footer {
		flex: 0 0 100%;
	}
