// ----------------------------------------------------------
// =Style guide!
// ----------------------------------------------------------

	// Selected text, uses vars set in vars partial
	@include format-selected-text;

	// Basic fade on background and colour
	%transition-links {
		@include transition(
			color 0.3s ease,
			background 0.3s ease
		);
	}

	// Transition everything
	%transition-fade {
		@include transition(all 0.3s ease);
	}

	// Placeholder for removing list styles
	%list-reset {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	// Placeholder for removing definition list styles
	%definition-reset {
		dd {
			margin: 0;
		}
	}

	html {
		@extend html;
		min-width: 20rem;
		font-size: 1rem; // Reset font size to 100% on desktop
	}

	body {
		background: palette(primary-background);
	}

	// Add a pointer on hover
	a,
	button,
	label {
		cursor: pointer;
	}

	html,
	button,
	input,
	select,
	textarea {
		color: palette(primary-text);
	}

	// Remove the gap between images, videos, audio and canvas and the bottom of their containers: h5bp.com/i/440
	audio,
	canvas,
	img,
	video {
	    vertical-align: middle;
	}

	// Reset fieldset borders
	fieldset {
	    border: 0;
	    margin: 0;
	    padding: 0;
	}

	// Import editor styles - links, headings, paragraphs etc
	@import 'style-guide-editor';
