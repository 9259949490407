// ----------------------------------------------------------
// =CTOC
// ----------------------------------------------------------

	// Hide the CTOC screen reader heading
	#secondary {
		h2 {
			@extend %visuallyhidden;
		}
	}

	#ctoc2,#ctoc2 ul {
		@extend %list-reset;
		@extend %hide-on-print;

		// Indent nested lists
		ul {
			padding-left: 1.25rem;
		}
	}