
$item-pad-xs: map-get($outer-grid, gutter) / 2;
$item-pad-sm: 15px;
$item-pad-md: 15px;
$item-pad-lg: 20px;
$item-pad-xlg: 25px;

$item-img-width-xs: 160px;
$item-img-width-sm: 100px;
$item-img-width-md: 120px;
$item-img-width-lg: 140px;
$item-img-width-xlg: 200px;

.physicians {
	//@extend .row;
	position: relative;
	background: #000000;

	.content__meta {
		background: $black;
		color: $white;
		padding-top: 0;
		height: 100%;

		&::after{
			z-index: -1;
		}

		@include mq(sm){
			flex: 1 1 30%;
			margin-top: 0;
		}
	}

		.content__meta-inner {
			overflow: hidden;
			padding: 2rem 0.2rem;

			@include mq(sm){
				margin-top: 0rem;
			}
		}

			.breadcrumbs__link {
				color: $white;
			}

			.content__title {
				float: left;
				font-size: font(size-m);
				line-height: 1;

				@include mq(sm){
					font-size: font(size-l);
				}
			}
	
		.physicians__listing-item {
			border-color: $almost-black;
		}

			.physicians__listing-item-clickable{
				&:hover {
					background: $almost-black;
				}
			}

				.physicians__listing-item-date {
					color: $dark-grey;
				}
			.physicians__content {
				background: palette(primary-background);
				color: palette(primary-text);
			}

	.content__main {
		display: block;
		border-top: none;
		left: 0;
		
		@include mq(sm){
			// position: static !important;
			top: 0 !important;
			position: relative !important;
			display: block;
			flex: 1 1 70%;
		}
	}
}

.physicians__pager {
	float: right;
}
	.physicians__prev,
	.physicians__next {
		float: left;
		width: 22px;
		height: 44px;
		height: font(size-xxxl);
		background: url('#{$sprites}arrows-yellow.png') left center no-repeat;
		cursor: pointer;
	}
	.physicians__next {
		background-position: right center;
		margin-left: 30px;
	}


.physicians__listing {
	@extend %list-reset;

	height: 100%;

	@include mq(sm) {
		height: 980px;
	}

	@include mq(md) {
		height: 980px;
	}

	@include mq(lg) {
		max-width: 620px;
		width: 100%;
		float: right;
		height: 1010px;
	}

	@include mq(xlg) {
		max-width: 620px;
		width: 100%;
		float: right;
		height: 1010px;
	}

	// Override the scrollbar color
	.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
	.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
	.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, 
	.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
		background-color: $yellow;
	}
}







	.physicians__listing-item {
		border-top: 2px solid $almost-white;
		overflow: hidden;
	}
		.physicians__listing-item-clickable {
			@extend %clearfix;
			text-decoration: none;
			position: relative;
			display: block;
			cursor: pointer;
			overflow: hidden;

			@include mq(xs){
				min-height: 140px;
			}

			@include mq(sm){
				min-height: 150px;
			}

			@include mq(md){
				min-height: 155px;
			}

			@include mq(xlg){
				min-height: 170px;
			}

			.physicians__listing-item.active &,
			.physicians__listing-item:hover & {
				background: $yellow;
				color: $black;

				&:after {
					opacity: 1;
				}
			}

			// Triangle
			&:after {
				@include translate(0, -50%);
				@include transition(opacity 0.3s ease);
				display: block;
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 30px 30px 30px 0;
				border-color: transparent $almost-black transparent transparent;
				position: absolute;
				right: 0;
				top: 50%;
				opacity: 0;
			}
		}
			.physicians__listing-item-img {
				display: none;
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				background: none center center no-repeat;
				background-size: cover;
				
				@include mq(xs){
					display: block;
					width: $item-img-width-xs;
				}
				@include mq(sm){
					display: none;
					width: $item-img-width-sm;
				}
				@include mq(md){
					display: block;
					width: $item-img-width-md;
				}
				@include mq(lg){
					width: $item-img-width-lg;
				}
				@include mq(xlg){
					width: $item-img-width-xlg;
				}
			}

			.physicians__listing-item-details {
				font-family: $secondary-font-stack-light;
				padding: 1rem 1rem 1rem 1.2rem;
				font-size: font(size-xxs);
				
				@include mq(xs){
					margin-left: $item-img-width-xs;
					padding: 1rem 1rem 1rem 1.2rem;
					font-size: font(size-xxs);
				}
				@include mq(sm){
					margin-left: 0;
					padding: $item-pad-sm;
					font-size: font(size-xxxs);
				}
				@include mq(md){
					margin-left: $item-img-width-md;
					padding: $item-pad-md;
					font-size: font(size-xxs);
				}
				@include mq(lg){
					margin-left: $item-img-width-lg;
					padding: $item-pad-lg;
					font-size: font(size-xxs);
				}
				@include mq(xlg){
					margin-left: $item-img-width-xlg;
					padding: $item-pad-xlg;
					font-size: font(size-xs);
				}
			}
				.physicians__listing-item-title {
					line-height: 1.14;
					font-size: font(size-m);

					@include mq(sm){
						font-size: font(size-xs);
					}
					@include mq(md){
						font-size: font(size-m);
					}
					@include mq(lg){
						font-size: font(size-m);
					}
					@include mq(xlg){
						font-size: font(size-l);
					}
				}
				.physicians__listing-item-date {
					display: block;
					margin-top: 20px;
					font-family: font(light);
					font-size: font(size-xxxs);
					line-height: 1;
					color: $grey;

					@include mq(lg){
						font-size: font(size-xxs);
					}
				}
				.physicians__listing-item-clinics {
					// padding-left: 20px;
					list-style: none;
				    padding: 0;
				}
				.physicians__listing-item-clinic--clickable {
					cursor: pointer;
					text-decoration: underline;

					&:hover {
						text-decoration: none;
					}
				}

		.physicians__content {
			display: none;
			padding: $item-pad-xs;
			height: 300px;

			@include mq(sm){
				padding: $item-pad-sm;
				display: none !important;
			}
			@include mq(md){
				padding: $item-pad-md;
			}
			@include mq(lg){
				padding: $item-pad-lg;
			}
			@include mq(xlg){
				padding: $item-pad-xlg;
			}

			p:last-of-type {
				margin-bottom: 0;
			}

			.physicians__listing-item.active & {
				display: block;
			}
		}

	#physicians__map {
		height: 300px;
		margin: 0;
		padding: 0;
		max-width: 100%;
		width: 100%;

		@include mq(sm) {
			height: 706px;
		}

		@include mq(md) {
			height: 756px;
		}

		@include mq(lg) {
			height: 856px;
		}

		@include mq(xlg) {
			height: 998px;
		}
	}