//	Animations
$mm_transitionDuration: 0.4s !default;
$mm_transitionFunction: ease !default;

//	Sizes
$mm_width: 0.8 !default;
$mm_minWidth: 140px !default;
$mm_maxWidth: 440px !default;

$mm_height: 0.8 !default;
$mm_minHeight: 140px !default;
$mm_maxHeight: 880px !default;

$mm_btnSize: 40px !default;
$mm_padding: 10px !default;
$mm_fontSize: 1rem !default;

//	Addon sizes
$mm_subpanelOffset: 40% !default;
$mm_subopenWidth: $mm_btnSize !default;
$mm_counterWidth: $mm_btnSize !default;
$mm_toggleHeight: $mm_btnSize - $mm_padding !default;
$mm_toggleWidth: ( $mm_toggleHeight * 2 ) - $mm_padding !default;
$mm_headerHeight: $mm_btnSize * 1.5 !default;
$mm_headerPaddingTop: $mm_headerHeight / 2 !default;
$mm_searchHeight: map-get($mobile-menu, search-height) + (map-get($mobile-menu, search-margins) * 2) !default;
$mm_searchfieldHeight: $mm_searchHeight !default;

//	Colors
$mm_baseBg: map-get($mobile-menu, outer-background) !default;
$mm_pageShadow: 0 0 20px rgba( 0, 0, 0, 0.5 ) !default;
$mm_color: map-get($mobile-menu, text) !default;
$mm_dimmedColor: map-get($mobile-menu, search-noresults-colour) !default;
$mm_emphasizedBg: map-get($mobile-menu, background-selected) !default;
$mm_highlightedBg: map-get($mobile-menu, background-hover) !default;
$mm_borderColor: map-get($mobile-menu, border-colour) !default;

//	Addon colors
$mm_toggleCheckedColor: #4bd963 !default;



@import "sizing";
@import "colors";
@import "arrows";
@import "borders";


//	Misc mixins
@mixin mm_vendor-prefix( $prop, $val )
{
	-webkit-#{$prop}: $val;
	-moz-#{$prop}: $val;
	-ms-#{$prop}: $val;
	-o-#{$prop}: $val;
	#{$prop}: $val;
}
@mixin mm_ellipsis
{
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
