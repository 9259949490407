.message {
	color: $black;
	border: 1px solid $green;
	background: $green;
	text-align: center;
	padding: 15px;
	margin: 0;
	position: relative;
	z-index: 9999;
	
	&.danger {
		color: $black;
		border-color: $yellow;
		background: $yellow;
	}
}