.container-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
}

.container{
    max-width: $container-max-width;
    width: 100%;
    padding: 0;
}

.container--small{
    max-width: $container-small-max-width;
    width: 100%;
    padding: 0;
}