
.content {}

	.content__meta {
		@include grid-col(1,1);
		padding: 0;
		background: $left-pane-colour none center top no-repeat;
		background-size: cover !important;
		background-position: top;
		height: 20rem;
		margin-top: 0rem;
		padding-top: 6rem;
		background-repeat: no-repeat !important;
		position: relative;
		overflow: hidden;

		@include mq(sm){
			background-position: top left;
			background-size: cover !important;
			margin-top: 0;
			height: auto;
			max-width: 32rem;
			@include grid-col(1,3);
		}

		@include mq(xlg){
			@include grid-col(1,2);
			width: calc(50% - 300px);
		}

		&::after{
			content: "";
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    width: 100%;
	    height: 100%;
			width: auto;
			height: 40rem;
			background-image: linear-gradient(to bottom, rgba(0,0,0,.6), rgba(255,255,255,0));
		}
	}

		%content__inner {
			max-width: 620px;
			margin: 0 auto;
			padding: 60px map-get($outer-grid, gutter)/2;

			@include mq(sm){
				margin-top: -6rem;
				padding-left: 20px;
				padding-right: 20px;
			}

			@include mq(md){
				padding-left: 40px;
				padding-right: 40px;
			}

			@include mq(lg){
				padding-left: 75px;
				padding-right: 75px;
			}
		}

		.content__meta-inner {
			@extend %content__inner;
			position: relative;
			z-index: 2;

			@include mq(md){
				margin-right: 0;
			}

			@include mq(lg){
				padding-left: 3rem;
			}
		}

		.content__title {
			margin-bottom: 1rem;
			font-family: $primary-font-stack;
			font-size: 1.5rem;
			color: $yellow;

			@include mq(sm){
				font-size: 1.8rem;
			}

			@include mq(md){
				font-size: 2rem;
			}

			@include mq(lg){
				font-size: 2.5rem;
			}
		}

		.content__title.find-a-sep {
			padding: 0 0.5rem;
		}

			.content__social {}
				%content__social-item {
					float: left;
				}
				%content__social-item--twitter {
					@extend %content__social-item;
				}
				%content__social-item--facebook {
					@extend %content__social-item;
				}
				%content__social-item--linkedin {
					@extend %content__social-item;
				}

	.content__main {
		width: 100%;
		border-top: 2px solid $almost-white;

		.content__meta + & {
			@include grid-col(1,1);
			padding: 0;

			@include mq(sm){
				@include grid-col(2,3);
			}

			@include mq(xlg){
				@include grid-col(1,1);
				width: calc(50% + 300px);
			}
		}
	}
		.content__main-inner {
			@extend %content__inner;
			max-width: 1200px;
			overflow: hidden;
			margin-top: 0;

			@include mq(sm){
				min-height: 40rem;
			}

			.content__meta + .content__main & {
				@include mq(xlg){
					// float: left;
				}
			}
		}

			.content__main-title {}
			.content__sub-text {
				color: $dark-grey;
				font-size: font(size-xxs);
			}

	.content__social-title {
		float: left;
		clear: both;
		margin-top: 50px;
	}

	.content__social {
		@extend %list-reset;
		float: left;
		padding-top: 0;
		margin-top: 0;

		@include mq(md) {
			padding-top: 0;
			margin-top: 0;
			padding-left: 0;
		}
	}

	.content__social-item {
		@extend %list-item-reset;
		display: block;
		float: left;
		width: 22px;
		height: 20px;
		margin-left: 15px;
		cursor: pointer;

		> span {
			display: block !important;
			text-indent: -9999px;
			width: 100% !important;
			height: 100%;
			background-position: center center;
			background-repeat: no-repeat;
		}
	}

	.content__social-item--twitter {
		@extend .content__social-item;

		> span {
			background-image: url("#{$icons}twitter-white.png");
			background-size: cover;
		}
	}
	.content__social-item--facebook {
		@extend .content__social-item;

		> span {
			background-image: url("#{$icons}facebook-white.png");
			background-size: contain;
		}
	}
	.content__social-item--linkedin {
		@extend .content__social-item;

		> span {
			background-image: url("#{$icons}linkedin-white.png");
			background-size: cover;
		}
	}
