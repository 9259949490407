.cpd-summary {
	@extend .container;
	padding-top: 30px;
	padding-bottom: 30px;

	@include mq(md) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
}
	.cpd-summary__intro {
		@extend .content-container;
		margin-bottom: 30px;
		text-align: center;
		font-size: font(size-xxs);

		@include mq(md) {
			margin-bottom: 60px;
		}
	}

	.cpd-summary__journal-container {
		position: relative;
		overflow: hidden;
	}

	.cpd-summary__journal-container.registrar-portal {
		margin-bottom: 5rem;
	}

	.cpd-summary__title {
		text-align: center;
		margin-bottom: 3rem;
		font-size: 2.25rem;
	}

	.cpd-summary__control {
		@extend .content-container;

		@include mq(sm) {
			height: 10rem;
			position: relative;
		}

		@include mq(md) {
			height: 130px;
		}
	}

	.cpd-summary__heading {
		font-family: font(condensed);
		font-size: font(size-xxl);
		text-transform: uppercase;

		@include mq(sm) {
			float: left;
		}
	}

	.cpd-summary__years {
		border: 2px solid $black;

		@include mq(sm) {
			@include translate(-50%, 0);
			float: left;
			position: absolute;
			left: 50%;
			top: 0;
		}
	}

	.cpd-summary__year-tab.registrar-year-tab {
		&:first-child {

			@include mq(sm) {
				width: 150px;
			}

		}
	}

	.cpd-summary__year-tab {
		@include grid-col(1,1);
		@include transition(color ease-in-out 300ms, background-color ease-in-out 300ms);
		font-family: font(condensed);
		font-size: font(size-m);
		color: $light-grey;
		text-decoration: none;
		text-align: center;
		// cursor: pointer;
		height: 41px;
		line-height: 41px;

		&.active {
			color: $white;
			background-color: $black;
			cursor: default;
		}

		// &:hover {
		// 	color: $white;
		// 	background-color: $black;
		// }

		@include mq(xs) {
			@include grid-col(1,3);
		}

		@include mq(sm) {
			width: 114px;
		}
	}

	.cpd-summary__export {
		@extend %buttons;
		height: 45px;
		line-height: 45px;
		text-align: center;
		color: $black;
		border: 2px solid $light-grey;
		padding: 0;
		margin-top: 15px;
		width: 120px;
		float: right;

		@include mq(sm) {
			margin-top: 0;
		}
	}

	.cpd-summary__score {
		font-family: font(condensed);
		font-size: font(size-m);
		text-transform: uppercase;
		float: right;
		margin-top: 2rem;
		margin-right: 15px;

		@include mq(sm) {
			width: 100%;
			position: relative;
			bottom: 0;
			font-size: font(size-xl);
			text-align: center;
		}

		@include mq(md) {
			bottom: 10px;
		}
	}

	.cpd-summary__categories {
		@extend .content-container;
		margin-bottom: 15px;

		@include mq(md) {
			margin-bottom: 80px !important;
		}
	}

	.cpd-summary__category {
		@include grid-col(1,1);
		text-align: center;
		margin-top: 15px;
		margin-bottom: 15px;

		@include mq(xs) {
			@include grid-col(1,2);
		}

		@include mq(md) {
			@include grid-col(1,4);
			margin-bottom: 0;
		}
	}

	.cpd-summary__category-name {
		font-family: font(light);
		font-size: font(size-sm);
		text-align: center;
		margin: 20px auto 25px;
		max-width: 230px;
		height: 60px;
	}

	// Code taken from http://jsfiddle.net/swfour/32Y8U/181/
	.cpd-summary__category-progress {
	    $circle-size: 200px;
	    $circle-background: $grey;
	    $circle-color: $yellow;
	    $inset-size: 190px;
	    $inset-color: $white;
	    $transition-length: 1.5s;
	    cursor: pointer;
	    width: $circle-size;
	    height: $circle-size;
	    background-color: $circle-background;
	    border-radius: 50%;
	    margin: 0 auto;

	    .circle {
	        .mask, .fill, .shadow {
	            width: $circle-size;
	            height: $circle-size;
	            position: absolute;
	            border-radius: 50%;
	        }
	        .mask, .fill {
	            -webkit-backface-visibility: hidden;
	            transition: -webkit-transform $transition-length;
	            transition: -ms-transform $transition-length;
	            transition: transform $transition-length;
	        }
	        .mask {
	            clip: rect(0px, $circle-size, $circle-size, $circle-size/2);

	            .fill {
	                clip: rect(0px, $circle-size/2, $circle-size, 0px);
	                background-color: $circle-color;
	            }
	        }
	    }
	    .inset {
	        width: $inset-size;
	        height: $inset-size;
	        position: absolute;
	        margin-left: ($circle-size - $inset-size)/2;
	        margin-top: ($circle-size - $inset-size)/2;
	        background-color: $inset-color;
	        border-radius: 50%;

	        div {
				@include translate(0, -50%);
	        	position: absolute;
	        	top: 50%;
	        	left: 0;
	        	display: block;
	        	width: 100%;
	        	text-align: center;
	        	line-height: 50px;

	        	span {
	        		font-family: font(light);
					font-size: 40px;
					font-weight: normal;
					vertical-align: middle;

					+ span {
						vertical-align: middle;
			        	font-family: font(light);
			        	font-size: font(size-m);
		        	}
	        	}
	        }

			div:nth-child(2) {
				margin-top: 2rem;
				font-size: 1rem;
			}

			&.smallerfont {
				div {
					span {
						font-size: 25px;
					}
				}
			}
	    }
	}

	.cpd-summary__record {
		@extend .primary-button;

		&.active,
		&:hover {
			background-color: $black;
			color: $white;
		}
	}

	.cpd-summary__journal {
		@extend .content-container;
		display: none;
		position: relative;
	}

	.cpd-summary__journal-heading {
		@extend h2;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 40px;
	}

	.cpd-summary__journal-table {

		tr {
			&.mandatory {
				background-color: $light-grey;
			}

			&.is_plr {
				td {
					background-color: $light-grey;
				}
			}

			&.is_shlr {
				td {
					background-color: $dark-grey;
					color: #e0e0e0;
				}
			}
		}

		th, td {
			font-size: font(size-xxs);
			padding-left: 10px;
			padding-right: 10px;
			padding-bottom: 0;
			vertical-align: top;

			&.desktop-only {
				display: none;

				@include mq(sm) {
					display: table-cell;
				}
			}
			&.center {
				text-align: center;
			}
		}

		th {
			font-family: font(condensed);
			padding-bottom: 20px;
			border-bottom: 1px solid $light-grey;
		}

		td {
			@include transition(padding-bottom ease-in-out 300ms);
			font-family: font(light);
			font-size: 14px;
			padding-top: 30px;
			padding-bottom: 20px;

			@include mq(md) {
				font-size: 16px;
			}

			&:last-child {
				// Button column
				padding-top: 20px;
			}

			span.mandatory,
			span.is_plr,
			span.is_shlr {
				line-height: 30px;
			    height: 30px;
			    display: inline-block;
			    vertical-align: middle;
			    position: relative;
			    padding-left: 30px;

				&:before {
					content: ' ';
					display: inline-block;
					width: 30px;
					height: 30px;
					background-color: $light-grey;
					border: 1px solid $light-grey;
				    position: absolute;
				    left: -10px;
				}
			}

			span.is_shlr {
				&::before {
					background-color: $dark-grey;
					border: 1px solid $dark-grey;
				}
			}
		}

		tr.recording {
			td {
				padding-bottom: 640px;

				@include mq(sm) {
					padding-bottom: 565px;
				}
			}
		}
	}

	.cpd-summary__journal-icon {
		width: 21px;
		height: 24px;
		display: inline-block;
		background: url("#{$icons}base-record.png") no-repeat center center;

		&.hidden {
			display: none;
		}
	}

	.cpd-summary__journal-form {
		//@include translate(-50%, 0);
		display: none;
		position: absolute;
		z-index: 2;
		//left: 50%;
		background-color: $almost-white;
		width: 100%;

		@include mq(xlg) {
			left: 50%;
			width: 99vw;
			margin-left: -49vw;
		}
	}

	.cpd-summary__journal-form-inner {
		@extend .content-container;
		padding-top: 40px;
		padding-bottom: 20px;
	}

	.cpd-summary__journal-form-row {
		overflow: hidden;

		@include mq(xlg) {
			margin-left: -(map-get($outer-grid, gutter) / 2);
			margin-right: -(map-get($outer-grid, gutter) / 2);
		}
	}

	.cpd-summary__journal-form-container {
		position: relative;
		margin-bottom: 20px;
		width: 100%;
		overflow: hidden;

		&-file-list{
			margin-bottom: 1rem;
		}
	}

	.cpd-summary__journal-inline-time-input{
		width: 100%;
    display: inline-block;

		@include mq(xs){
			width: 100%;
		}

		@include mq(md){
			width: 14rem;
		}
	}

	.cpd-summary__journal-form-container--file {
		@extend .cpd-summary__journal-form-container;
		//overflow: visible;
	}

	.cpd-summary__journal-form-file-link {
		display: none;
		float: left;
		margin: 15px 0 15px 15px;
		width: 130px;
		text-decoration: none;
	    line-height: 30px;
	    font-size: font(size-xxs);

	    @include mq(sm) {
	    	position: absolute;
	    	top: 13px;
			left: 100%;
			margin: 0 0 0 15px;
	    }
	}

	.cpd-summary__journal-form-input {
		max-width: 100%;

		@include mq(sm) {
			width: 222px;
		}
	}
	.cpd-summary__journal-form-input--long {
		@extend .cpd-summary__journal-form-input;

		@include mq(sm) {
			width: 360px;
		}
	}
	.cpd-summary__journal-form-input--date {
		@extend .cpd-summary__journal-form-input;
		padding-right: 15px;
		padding-left: 15px;
		background: url("#{$icons}calendar.png") no-repeat right center $white;
	}
	.cpd-summary__journal-form-input--clock {
		@extend .cpd-summary__journal-form-input--date;
		background-image: url("#{$icons}clock.png");
	}
	.cpd-summary__journal-form-input--plus {
		@extend .cpd-summary__journal-form-input--date;
		background-image: url("#{$icons}plus.png");
	}
	.cpd-summary__journal-form-input--file {
		@extend .cpd-summary__journal-form-input;
		opacity: 0;
	}
	.cpd-summary__journal-form-textarea {
		@extend .cpd-summary__journal-form-input;
		@include mq(sm) {
			width: 600px;
		}
	}

	.cpd-summary__journal-form-container-file {
		position: relative;
		@include mq(sm) {
			float: left;
		}
	}

	.cpd-summary__journal-form-label--file {
		@extend input;
		@extend .cpd-summary__journal-form-input--date;
		background-image: url("#{$icons}plus.png");
		position: absolute;
		width: 100%;
		height: 100%;
		color: $dark-grey;
		top: 0;
		left: 0;
	}

	.cpd-summary__journal-form-buttons {

	}

	.cpd-summary__journal-form-submit {
		margin-bottom: 15px;
	}

	.cpd-summary__journal-table {
		h5 {
			margin-top: 20px;
			margin-bottom: 5px;
		}
	}

	.cpd-summary__journal-table-data {
		> div {
			padding: 5px 0;
			border-bottom: 1px solid $grey;
			overflow: hidden;
		}
	}

	.cpd-summary__journal-old-update,
	.cpd-summary__journal-old-del {
		@extend .secondary-button;
		margin-right: 15px;
		width: auto;
		float: left;
	}
	.date-logname, .doc_icon{
		display: inline-block;
		padding-top: 7px;
	}
	.doc_icon {
		padding-left: 10px;
	}
	.cpd-summary__journal-item 	.cpd-summary__journal-icon{
		display: none;
		@include mq(sm) {
			display: block;
		}
	}
