//  -------------------------------------------------------------
// 	=Buttons
//	-------------------------------------------------------------

	// Styles common among buttons go here
	%buttons {
		display: inline-block;
		padding: 12px 25px 10px 25px;
		min-height: 36px;
		text-align: center;
		text-decoration: none;
		width: 100%;
		text-transform: uppercase;
		cursor: pointer;
		font-family: font(default);
		font-size: font(size-xs);
		line-height: 1;
		border: none;

		@include mq(sm) {
			width: auto;
		}
	}

	// These match the base button names in _vars.scss
	$button-types: 'primary', 'secondary', 'tertiary', 'quaternary', 'quinary', 'senary', 'septenary';

	// Loop through each button, get background, text and hover/active colours
	@each $name in $button-types {
		.#{$name}-button {
			@extend %buttons;
			background: palette(#{$name}-button);
			color: palette(#{$name}-button-text);

			&:visited {
				color: palette(#{$name}-button-text);

				&:hover {
					color: palette(#{$name}-button-text-active);
				}
			}

			&:hover {
				background: palette(#{$name}-button-hover);
				color: palette(#{$name}-button-text-active);
			}

			&:active {
				background: palette(#{$name}-button-active);
				color: palette(#{$name}-button-text-active);
			}
		}
	}

	.form_submit{

		input[type='submit']{

			background: $yellow;
			border: none;
			text-transform: uppercase;
			font-family: $primary-font-stack;
			height: auto;
			padding: .8rem 1.5rem;
			font-size: 1rem;
			min-height: 1.8rem;

			&:hover, &:active{
				background: $faded-yellow;
			}

		}
	}

	.secondary-button.cpd-summary__journal-close-registrar-form,
	.cpd-summary__journal-old-update.registrar-portal-button,
	.cpd-summary__journal-old-del.registrar-portal-button {
		&:hover,
		&:focus {
			background-color: #252525;
			color: $white;
		}
	}
