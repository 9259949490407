.grid-icon {
	display: inline-block;

  &:hover > span > span{
    background-color: $yellow;
    border: solid 2px $yellow;
  }

}

.grid-icon > .layer {
	width: 100%;
	position: absolute;
	left: 0;
}

.grid-icon--fill {
	position: relative;
	width: 27px;
	height: 27px;
}

.grid-icon--fill > .layer {
	height: 12px;
}

.grid-icon--fill > .layer--primary {
	top: 0;
}

.grid-icon--fill > .layer--secondary {
	bottom: 0;
}

.grid-icon--fill > .layer > span {
	position: absolute;
	display: block;
	width: 12px;
	height: 100%;
	box-sizing: border-box;
	border: solid 2px $black;
  background: $black;
}

.grid-icon--fill > .layer > span:first-child {
	left: 0;
}

.grid-icon--fill > .layer > span:nth-child(2) {
	right: 0;
}

.list-icon{
  display: inline-block;

  &:hover > span > span {
    background-color: $yellow;
  }
}

.list-icon > .layer{
  width: 100%;
	position: absolute;
	left: 0;
}

.list-icon--line{
  position: relative;
	width: 27px;
	height: 27px;
}

.list-icon--line > .layer {
	height: 27px;
}

.list-icon--line > .layer--primary {
	top: 0;
}

.list-icon--line > .layer > span {
	position: relative;
	display: block;
	width: 100%;
	height: .2rem;
	box-sizing: border-box;
  background: $black;
  margin: .45rem 0;

  &:first-child{
    margin-top: 0;
  }
}

.grid-icon--active > .layer > span {
  background-color: $yellow;
  border: solid 2px $yellow;
}

.list-icon--active > .layer > span {
  background-color: $yellow;
  border: solid 2px $yellow;
}
