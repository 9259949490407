.member-hero {
	//@extend .container;
	background-color: $black;
	margin-top: 7rem;
}

.member-hero.adjust-member-area-margin {
	margin-top: 0;
}

	.member-hero__inner {
		@extend .content-container;
		max-width: 1470px !important;
	}

	.member-hero__row {
		@extend .row;
		@include flex-container;
	}

	.member-hero__section {
		@include grid-col(1,1);
		color: $white;
		padding-top: 15px;
		padding-bottom: 15px;

		&:last-child {
			display: none;
		}

		@include mq(sm) {
			@include grid-col(1,2);
			padding-bottom: 15px;
			padding-top: 47px;
		}

		@include mq(md) {
			padding: 40px 15px;
			width: 48%;
			left: -34%;
			position: relative;

			&:nth-child(2) {
				left: -32%;
			}

			&:last-child {
				display: block;
				width: 19%;
				left: auto;
				padding: 40px 15px;
			}
		}

		@include mq(lg) {
			@include grid-col(1,3);
			padding: 40px 50px;

			&:nth-child(2) {
				@include grid-col(1,3);
				@include grid-pull(1,3);
				padding: 40px 15px;
				left: -32%;
			}

			&:last-child {
				@include grid-col(1,3);
				padding: 40px 50px 40px;
			}
		}

		@include mq(1500px) {
			&:nth-child(2) {
				padding-left: 0;
			}
		}
	}
	.member-hero__section--photo {
		@extend .member-hero__section;
		@include grid-col(1,1);
		padding: 0;
		text-align: center;
		position: relative;
		overflow: hidden;

		@include mq(sm) {
			@include grid-col(1,2);
			width: 49%;
			text-align: left;
		}

		@include mq(md) {
			width: 32%;
			left: 48%;
			padding-bottom: 0;
		}

		@include mq(lg) {
			@include grid-col(1,3);
			@include grid-push(1,3);
			width: 32%;
			padding-bottom: 0;
		}

		img {
			max-width: 100%;
			height: auto;

			@include mq(sm) {
				@include translate(-50%, -50%);
				position: absolute;
				left: 50%;
				top: 50%;
				max-width: none;
				min-width: 100%;
				min-height: 100%;
				height: auto;
				width: auto;
			}
		}
	}
	.member-hero__section--basic {
		@extend .member-hero__section;

		@include mq(md) {
			@include grid-col(1,1);

			&:last-child {
				@include grid-col(1,1);
			}
		}

		@include mq(lg) {
			@include grid-col(1,1);

			&:last-child {
				@include grid-col(1,1);
			}
		}
	}

	.member-hero__section-name {
		margin: 0 0 15px;
		padding: 0;
	}

	.member-hero__section-role-list {
		@extend %list-reset;
		margin-bottom: 15px;
	}

	.member-hero__section-role-item {
		@extend %list-item-reset;
		font-family: font(medium);
		font-size: font(size-xxs);
		display: inline-block;
		padding: 0 15px !important;
		border-left: 1px solid $white;

		&:first-child {
			padding-left: 0 !important;
			border-left: none;
		}
	}

	.member-hero__section-info {
		display: block;
		font-family: font(light);
		font-size: font(size-xxs);
		line-height: 1.65;
	}
	.member-hero__section-info--email {
		@extend .member-hero__section-info;
		color: $yellow;
	}

	.member-hero__section-button {
		@extend .primary-button;
		margin-top: 30px;
	}

	.member-hero__section-heading {
		color: $yellow;
		margin-top: 15px;
		margin-bottom: 0;

		@include mq(sm) {
			margin-top: 0;
		}

		@include mq(md) {
			margin-bottom: 15px;
		}
	}
	.member-hero__section-heading--basic {
		@extend .member-hero__section-heading;
		@include grid-col(1,5);
		text-align: center;

		@include mq(md) {
			margin-bottom: 5px;
		}
	}

	.member-hero__section-link {
		text-transform: uppercase;
		font-family: font(condensed);
		font-size: font(size-l);
		color: $white;
		display: block;

		@include mq(md) {
			margin-bottom: 7px;
		}

		@include mq(lg) {
			font-size: 1.7rem;
		}

		&:hover {
			color: $yellow;
			text-decoration: underline;
		}

		&.active {
			color: $yellow;
			text-decoration: none;
			cursor: default;

			&:hover {
				text-decoration: none;
			}
		}
	}
	.member-hero__section-link--basic {
		@extend .member-hero__section-link;
		@include grid-col(1,2);
		font-size: font(size-m);
		text-align: center;

		@include mq(lg) {
			font-size: 1.25rem;
		}

		.col-3 & {
			@include grid-col(1,3);
		}
		.col-4 & {
			@include grid-col(1,4);
		}
		.col-5 & {
			@include grid-col(1,5);
		}
		.col-6 & {
			@include grid-col(1,6);
		}

		.col-7 & {
			@include grid-col(1,7);
		}


		@include mq(md) {
			margin-bottom: 0;
		}
	}

	.member-hero__section-helper-text {
		font-family: font(light);
		font-size: font(size-xxxs);
	}
	.member-hero__section-helper-text--basic {
		padding-top: 1rem;

		@extend .member-hero__section-helper-text;
		@include grid-col(1,2);
		text-align: center;
		clear: both;
		float: right;

		.col-3 & {
			@include grid-col(1,3);
			float: right;
		}
		.col-4 & {
			@include grid-col(1,4);
			float: right;
		}
		.col-5 & {
			@include grid-col(1,5);
			float: right;
		}
		.col-6 & {
			@include grid-col(1,6);
			float: right;
		}
	}
