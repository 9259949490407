.physician-bar {
	text-align: center;
	background-color: $black;
	padding: 8rem 0 3rem 0;

	@include mq(sm){
		padding: 2rem 0 3rem 0;
	}

	@include mq(md){
		padding: 0 0 1rem 0;
	}
}

.physician-bar__home{
	padding-top: 3rem;

	@include mq(md){
		padding-top: 1rem;
	}
}

	.physician-bar__form {
		margin: .1rem auto;
		padding: 0.875rem 0 0 0;
		width: auto;
		text-align: center;

		@include mq(sm) {
			max-width: 100%;
			margin: 0 2.3rem;
			width: auto;
			padding: 0;
		}

		@include mq(md){
			padding-top: .5rem;
			padding-bottom: .7rem;
			width: 38rem;
			display: inline-block;
		}

		@include mq(lg) {
			width: 46rem;
			padding-bottom: 0.4rem;
		}
		@include mq(xlg) {
			width: 47rem;
		}
	}

	.physician-bar__form-heading {
		color: $white;
		text-transform: uppercase;
		font-family: $primary-font-stack;
		font-size: 1.5rem;
		font-weight: normal;
		width: 70%;
		display: inline-block;
		text-align: center;
		margin-bottom: 0;
		font-weight: 300;

		@include mq(sm){
			margin-bottom: 0.937rem;
		}

		@include mq(md){
			font-size: 1.2rem;
			padding-top: .8rem;
			width: 100%
		}

		@include mq(lg){
			font-size: 1.3rem;
			padding-top: 0.4rem;
			margin-bottom: 0.5rem;
		}

	}

	.physician-bar__form-container {
		position: relative;
		margin-bottom: 5px;
		font-family: $primary-font-stack;
		font-weight: 300;
		width: 90%;
		display: inline-block;

		input{
			height: 2.925rem;
			padding: 0 0 0 .8rem;
		}

		input::placeholder{
			font-family: $primary-font-stack;
			font-weight: 200;
			font-size: 1rem;
		}

		@include mq(sm){
			float: none;
			margin-right: 0;
		}

		@include mq(md){

			input{
				padding: 0 .5rem 0 1rem;
				height: 2.9rem;
				width: 14rem;
			}

			input::placeholder{
				font-family: $primary-font-stack;
				font-weight: 200;
				font-size: .8rem;
			}
		}

		@include mq(lg){

				input{
					height: 2.9rem;
					width: 18rem;
				}

				input::placeholder{
					font-size: 1rem;
					padding:0 0 0 .3rem;
				}
		}

		@include mq(xlg){

			input{
				width: 18.8rem;
			}
		}

	}

	input[type="submit"].physician-bar__form-submit {
		// @extend .primary-button;
		font-size: 1.1rem;
		height: auto;
		width: 90%;
		padding: .8rem 0;
		line-height: normal;

		@include mq(sm){
			width: 24%;
			padding: .75rem 0;
		}

		@include mq(md){

			min-height: 0;
	    height: auto;
	    padding: .8rem 2rem;
	    vertical-align: top;
	    font-size: 1rem;
			width: auto;
		}

		@include mq(lg){

			font-size: 1rem;
			padding: .8rem 2rem;
		}

	}

	.physician-bar__form-container {



		.in-field-label{
			top: 10px;
			left: 14px;
			font-family: $primary-font-stack;
			font-weight: 200;
			font-size: .8rem;
		}

		@include mq(sm) {
			width: 250px;
		}

		@include mq(md){
				width: auto;
		}

		@include mq(xlg) {
			width: 300px;
		}
	}

	.physician-bar__form-input {
		width: 100%;
		height: 2.2rem;

		input::placeholder{
			font-size: 1rem;
		}
	}

	.physician-bar__form-input--location {
		@extend .physician-bar__form-input;
		background: url("#{$icons}location-yellow.png") no-repeat center right $white;
		padding-right: 50px;
		height: 2.2rem;

		@include mq(md){
			background: url("#{$icons}location-yellow-md.png") no-repeat center right $white;
		}

		@include mq(lg){
			background: url("#{$icons}location-yellow.png") no-repeat center right $white;
		}


	}
