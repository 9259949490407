$mobile-header-height-home: 60px;
$header-height-home: 90px;

.image-hero {
	//@extend .container;
	position: relative;
	height: 36rem;
	overflow: hidden;
	width: 100%;
	// margin-top: -($mobile-header-height-home);
	background-repeat: no-repeat;
	background-position: right;
	background-size: cover;

	@include mq(sm){
		height: 22.5rem;
		padding: 0;
		background-position: center center;
	}

	@include mq(md){
		// top: -($header-height-home);
		height: 48.75rem;
		margin-bottom: -($header-height-home);
	}

	@include mq(lg){
		height: 56rem;
	}

	@include mq(xlg){
		height: 56rem;
	}
}

.image-hero-slider{

		height: 43rem;
		background: url('/img/backgrounds/banners/hero-banner-1-mobile.png') no-repeat;
		background-size: cover;
		background-position: center;

		&::after{
			content: "";
			position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    width: 100%;
	    height: 100%;
	    width: auto;
	    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0));
		}

		.slick-list{
			display: none;
		}

		@include mq(md){
			background: none;
			.slick-list{
				display: block;
			}
		}

		@include mq(lg){
			height: 50rem;
		}

		@include mq(xlg){
			height: 50rem;
		}
}

.heading__hero-content{

	height: auto;
	overflow: hidden;
	width: 100%;
	background: none;
	text-align:center;
	font-family: $primary-font-stack;
	color: $white;
	text-transform: uppercase;
	margin-top: 3rem;

	.heading__title-small{
		font-size: 1.2rem;
		font-weight: 200;
		line-height: 1.8rem;
		font-weight: 200;
	}

	.heading__title-large{
		font-size: 5.8rem;
		line-height: 5rem;
		letter-spacing: -0.3rem;
	}

	.heading__hero-buttons{
		margin-top: 4.8rem;
		font-weight: 200;

		a{
			font-size: 1.1rem;
			font-weight: normal;
			width: 90%;
			margin: .3rem 0 0 0;
			padding: .8rem 0;
			line-height: normal;
			height: auto;
			min-height: 0;
		}
	}

	@include mq(sm){
		top:38%;
		margin-top: 4rem;

		.heading__title-small{
			font-size: 2.2rem;
			line-height: 2.2rem;
			font-weight: 200;
		}

		.heading__title-large{
			font-size: 9.5rem;
			line-height: 9rem;
			letter-spacing: -0.55rem;
		}

		.heading__hero-buttons{
			margin-top: 1.5rem;
			font-weight: 300;

			a{
				font-size: 1.1rem;
				min-height: 1.7rem;
				padding: .8rem;
				width: 20%;
			}
		}

		.heading__button-spacing{
			margin-left: 1rem;
		}

	}

	@include mq(md){

		top: 0;
		margin-top: 3rem;

		.heading__title-small{

			font-size: 1.6rem;
			line-height: 1.25rem;
		}

		.heading__title-large{

			line-height: 7.5rem;
			font-size: 8rem;
			letter-spacing: -0.55rem;
		}

		.heading__hero-buttons{
			margin-top: 1.5rem;
			font-weight: 300;

			a{
				font-size: 1.5rem;
				min-height: 1.8rem;
				padding: .8rem 2.3rem;
			}
		}

	}

	@include mq(lg){

		top: 0;
		margin-top: 9.2rem;
		text-align: center;


		.is-width-restricted{
			max-width: 60rem;
			display: inline-block;
		}

		.heading__title-small{
			font-size: 2.8rem;
			line-height: 2.2rem;
			font-weight: 300;
		}

		.heading__title-large{
			line-height: 10rem;
			font-size: 14rem;
			letter-spacing: -0.55rem;
			margin-top: 1.7rem;
		}

		.heading__hero-buttons{
			margin-top: 3.8rem;

			a{
				font-size: 1.5rem;
				min-height: 1.8rem;
				padding: .8rem 2.3rem;
				width: auto;
			}
		}

		.heading__button-spacing{
			margin-left: 1rem !important;
		}

	}

}
