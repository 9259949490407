.heading__navigation{
	text-align: center;
	margin-top: 1.2rem;
	display: none;

	@include mq(md){
		display: block;
	}

	@include mq(lg){
		margin-top: 2.2rem;
	}

	@include mq(xlg){
		margin-top: 2.8rem;
	}
}

.header__nav{

	ul{
		padding-left: 0;

		// Show level-2 menu on the left hand side on 
		// smaller displays since we don't have enough
		// space on the right handn side.
		& > li:last-child{
			&:hover{
				ul.level-1{
					&:hover{
						ul.level-2{
							right: auto;
							left: auto;

							@include mq(lg){
								right: 100%;
								left: auto;
							}

							@include mq(xlg){
								left: auto;
								right: auto;
							}
						}
					}
				}
			}
			
		}

		& > li{

			color: $white;
			font-family: $primary-font-stack;
			display: inline-block;
			padding: 0;
			margin: 0;
			font-weight: 300;

			a{
				
				font-size: 0.8rem;
				color: $white;
				border:none;
				padding: .8rem .8rem;
				text-decoration: none;
				text-transform: uppercase;
				margin: 0;
				display: block;
				outline: none;

				@include mq(lg){
					font-size: 1rem;
				}

			}

			&:hover{
				background-color: $yellow;

					a{
						color: $navigation-hover-color;
					}

			}

		}

		& > li ul {
			display: none;
			position: absolute;
			background-color: $navigation-hover-color;
			z-index: 8;
			margin:0;
			padding: 0;

			li{
				display: block;
				padding-left: .5rem;
				padding-right: .5rem;
				min-width: 18.75rem;
				max-width: 19rem;
				
				a{
					color: $white;
					position: relative;

					span{
						right: 1rem;
						position: absolute;
						top: 0.2rem;

						&::after{
							content: "✚";
							position: absolute;
							font-size: 0.9rem;
						}
					}
				}

			}

			& > li ul{
				display: none;


				li{
					a{
						color: $white;
					}
				}
			}
		}

		& > li:hover ul.level-1{
			display: block;
			padding: .5rem 0;
			border-bottom: 2px solid $yellow;
			min-width: 19rem;

			li{
				display: block;
				text-align: left;
				padding-left: 1rem;
				padding-right: 1rem;
				min-width: 18.75rem;
				max-width: 19rem;

				&:not(first-child){
					padding-top: 1rem;
				}

				&:last-child{
					padding-bottom: 1rem;
				}

				a{	
					padding: 0;
					color: $white;
					text-transform: capitalize;
					font-weight: 300;
				}
			}

			li:hover{
				background: none;

				a{
					color: $yellow;
					font-weight: normal;
				}
			}

			& > li:hover ul.level-2{
				display: block;
				margin-left: 18rem;
				margin-top: -2.85rem;
				padding-left: 0.5rem;
				padding: .5rem 0;
    			border-bottom: 2px solid $yellow;

				li{
					a{
						color: $white;
						font-weight: 300;
					}

					a:hover{
						color: $yellow;
					}
				}
			}
		}

		& > li ul li{
			display: block;
		}
	}
}

.navigation__trigger-mobile-nav {
	display: inline-block;
	float: right;
	margin: 1rem 1.5rem 0 0;
	padding: 0;
	width: 25px;
	height: $mobile-header-height;
	background: url("#{$icons}menu-mobile-white.png") no-repeat center center;
	background-size: contain;

	@include mq(sm){
		width: 2rem;
	}

	@include mq(md){
		height: $header-height;
		width: $header-height;
	}

	@include mq(md){
		display: none;
	}
}

.navigation__mobile{
	display: inline-block;
	width: 10rem;
	z-index: 10;

	@include mq(sm){
		position: absolute;
		right: 0;
	}
}