.home-news {
	padding-top: 30px;
	position: relative;
	overflow: hidden;
	margin-bottom: -30px;

	@include mq(md) {
		margin-bottom: -60px;		
	}	

	@include mq(lg) {
		margin: 0 auto -60px;
		max-width: 1152px;
	}

	@include mq(xlg) {
		max-width: 1800px;
	}
}

	.home-news__list {
		@extend %list-reset;

		@include mq(sm) {
			width: 100%;
			float: left;
		}
	}

	.home-news__item {
		@extend %list-item-reset;
		clear: both;
		border-bottom: 1px solid $almost-white;
		overflow: hidden;
		padding: 20px 15px;

		&:first-child {
			border-top: 1px solid $almost-white;
		}

		@include mq(sm) {
			padding: 40px 0;
			// height: 202px;
		}

		@include mq(xlg) {
			// height: 195px;
		}
	}

	.home-news__container {
		max-width: 450px;
		margin: 0 auto;

		@include mq(sm) {
			// margin: 0;
			max-width: 700px;
			padding: 0 15px 0 15px;
		}

		@include mq(md) {
			max-width: 100%;
			padding-right: 622px;	
		}

		@include mq(lg) {
			padding-left: 45px;
		}

		@include mq(xlg) {
			padding-right: 1350px;
		}
	}

	.home-news__date {
		width: 75px;
		text-transform: uppercase;
		text-align: center;
		float: left;
		padding-top: 5px;

		@include mq(sm) {
			width: 110px;
		}

		@include mq(md) {
			width: 75px;
		}

		@include mq(lg) {
			width: 110px;
		}
	}

	.home-news__date-month {
		font-family: font(condensed);
		font-size: font(size-xl);
		display: block;
		line-height: 0.8;

		@include mq(sm) {
			font-size: font(size-xxl);
		}
		@include mq(md) {
			font-size: font(size-xl);
		}
		@include mq(lg) {
			font-size: font(size-xxl);
		}
	}

	.home-news__date-day {
		font-family: font(medium);
		font-size: font(size-xxxl);
		display: block;
		clear: both;
		line-height: 0.9;

		@include mq(sm) {
			font-size: font(size-xxxxl);
		}
		@include mq(md) {
			font-size: font(size-xxxl);
		}
		@include mq(lg) {
			font-size: font(size-xxxxl);
		}
	}

	.home-news__summary {
		overflow: hidden;

		@include mq(sm) {
			max-height: 52px;
		}
	}

	.home-news__content {
		text-align: left;
		padding-left: 90px;		
    	padding-right: 10px;

    	@include mq(sm) {
			padding-left: 120px;
    	}
    	@include mq(md) {
			padding-left: 90px;
    	}
    	@include mq(lg) {
			padding-left: 120px;
			padding-right: 50px;
    	}
	}

	.home-news__link {
		text-decoration: none;
		font-family: font(condensed);
		font-size: font(size-m);
		text-transform: uppercase;
		color: $black;
		margin-top: 20px;
		display: inline-block;

    	@include mq(sm) {
			margin-top: 40px;
    	}
	}

	