.meet-the-team--container{

  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;


    li{
      cursor: pointer;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      height: 480px;
      min-height: 30rem;
      max-height: 32rem;
      overflow: hidden;
      position: relative;
      width: 100%;
      margin-top: 2rem;
      display: inline-block;
      text-align: left;
      border: 0;
      box-sizing: border-box;
      vertical-align: top;


      &:first-child{
        margin-top: 0;
      }

      @include mq(sm){

        &:first-child{
          margin-top: 2rem;
        }

        width: 50%;
        min-width: 14rem;
        max-height: 16rem;
      }

      @include mq(md){
        width: 33%;
      }

      a{
        display: block;
        padding: 1.5rem 1.5rem;

        &:link, &:hover, &:active{
          color: #ffffff;
          text-decoration: none;
        }

      }

      &:hover {

        .member-overlay{
          z-index: 3;
          opacity: .9;
        }

        .member--description{
          opacity: 1;
        }
      }

      .member-overlay{
        background: $yellow;
        position: absolute;
        height: 32rem;
        width: 60rem;
        display: block;
        opacity: 0;
        transition: opacity .3s ease-in;
      }

      .member-details-overlay{
        z-index: 2;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
        width: 60rem;
        height: 8rem;
      }

      .member--name{
        color: $white;
        font-family: $primary-font-stack;
        z-index: 4;
        position: relative;

      }

      .member--position{
        color: $white;
        font-family: $primary-font-stack;
        z-index: 4;
        position: relative;

      }

      .member--description{
        margin-top: 1rem;
        color: $white;
        font-family: $primary-font-stack;
        font-size: 1rem;
        opacity: 0;
        z-index: 4;
        position: relative;
        transition: opacity .3s ease-in;
        max-height: 20rem;
        overflow-y: hidden;
      }
    }

    li.helper-hidden-on-smaller-device{
        min-height: 1px;
        height: 1px;
    }

  }
}
