/*
	jQuery.mmenu offcanvas addon CSS
*/

@import "../inc/variables";


//	Animations
.mm-page,
.mm-fixed-top,
.mm-fixed-bottom
{
	@include mm_vendor-prefix( "transition", none $mm_transitionDuration $mm_transitionFunction );

	// border is needed to force transitionend event even if no other value changed
	@include mm_vendor-prefix( "transition-property", ( top, right, bottom, left, border ) );
}

.mm-page,
#mm-blocker
{
	margin: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.mm-page
{
	border: 0px solid rgba( 0, 0, 0, 0 );
}
html.mm-opening
{
	.mm-page
	{
		border: 0px solid rgba( 1, 1, 1, 0 );
	}
}

//	Fixed elements
.mm-fixed-top,
.mm-fixed-bottom
{
	position: fixed;
	left: 0;
}
.mm-fixed-top
{
	top: 0;
}
.mm-fixed-bottom
{
	bottom: 0;
}

//	Container
html.mm-opened
{
	overflow: hidden;
	position: relative;
	
	body
	{
		overflow: hidden;
	}
}

//	Page + blocker
html.mm-opened .mm-page
{
	@include mm_vendor-prefix( "box-sizing", border-box );
	position: relative;
}
html.mm-background .mm-page
{
	background: inherit;
}
#mm-blocker
{
	@include mm_vendor-prefix( "transition", none $mm_transitionDuration $mm_transitionFunction );

	// border is needed to force transitionend event even if no other value changed
	@include mm_vendor-prefix( "transition-property", ( top, right, bottom, left, border ) );
	
	opacity: 0.5;
	background: #000000;
	//background: url( data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw== ) transparent;
	display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 999999;
}
html.mm-opened,
html.mm-blocking
{
	#mm-blocker
	{
		display: block;
	}
}

//	Menu
.mm-menu
{
	&.mm-offcanvas
	{
		display: none;
		position: fixed;
	}
	&.mm-current
	{
		display: block;
	}
}


@include mm_sizing;