.view-registrar-summary {

  h2 {

    align-items: center;

    @include mq(sm)
    {
      display: flex;
      align-items: center;
    }

    span {
      flex: 1 1 auto;
      display: block;

      @include mq(sm)
      {
      width: auto;
      }
    }

    span:nth-child(2) {
      font-size: 1.375rem;

      @include mq(sm)
      {
        text-align: right;
      }
    }
  }

  .vrs-learning-pathway-label {
    background-color: $black;
    color: $yellow;
    padding: 1rem 0.5rem;
  }

  .vrs-course-item {
    background-color: $yellow;
    padding: 0.5rem 0.5rem;
    font-size: 1.25rem;

    .vrs-course-stats {
      font-size: 0.9rem;
      display: block;
    }
  }

  .vrs-module-item {
    font-weight: 300;
    display: block;
    font-size: 1rem;
    padding: 1rem 0.5rem;

    @include mq(sm)
    {
      display: flex;
    }

    .vrs-module-label {
      flex: 1 1 auto;
    }

    .vrs-module-stats {
      flex: 0 0 30%;
      font-size: 0.875rem;

      @include mq(sm)
      {
        text-align: right;
        font-size: 1rem;
      }
    }

    .vrs-module-year {
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }

    .vrs-module-link {
      font-size: 0.875rem;

      a:link,
      a:visited {
        text-decoration: none;
        font-size: 0.875rem;
      }

      a:hover,
      a:active,
      a:focus {
        text-decoration: underline;
      }
    }
  }

  .vrs-module-item.grey {
    background-color: $faded-white;
  }
}
