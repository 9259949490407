// ----------------------------------------------------------
// =Style guide!
// This file is included in editor.css and only includes elements used within the editor
// ----------------------------------------------------------

	// Headings h1 - h6
	%headings {
		margin: 0 0 $vertical-margins 0;
		// font-family: font(medium);
		font-family: $primary-font-stack;
		letter-spacing: -1px;
		font-weight: normal;
		line-height: 1.14
	}

	@each $header, $size in $heading-sizes {
		#{$header} {
			@extend %headings;
			@include font-size(map-get($heading-mobile-sizes, $header));
			// font-family: font(condensed);
			font-family: $primary-font-stack;
			text-transform: uppercase;
			letter-spacing: -1px;
			@include mq(md) {
				@include font-size($size);
			}
		}
	}

	legend {
		@extend h3;
	}

	// Set base font family and colour
	body {
		font-family: font(default);
		font-weight: normal;
		font-size: font(size-sm);
		color: palette(primary-text);
		line-height: 1.36;
	}

	// Bold text
	b, strong {
		font-family: $primary-font-stack;
		font-weight: normal;
		color: $black;
	}


	// Paragraphs
	p {
		color: $grey-text;
		font-family: $secondary-font-stack-light;
		font-size: 1.3rem;
		margin: 0 0 $vertical-margins 0;
	}


	// Links
	a {
		color: palette(link);
		@extend %transition-links !optional;
		text-decoration: underline;
		// font-family: $secondary-font-stack;
		// color: $blue;
		// &:visited {
		// 	color: palette(link-visited);
		// }

		&:hover,&:active {
			// color: $blue;
			text-decoration: none;
		}
	}

	.cms-content{
		a:link,a:visited,a:active{
			font-family: $secondary-font-stack-light;
			font-size: 1.3rem;
			color: $blue-link;
			text-decoration: none;
		}

		a:hover{
			color: $blue-link;
			text-decoration: underline;
		}

		ul,ol{
			padding: 0 0 0 85px;

			li{
				padding-bottom: .3rem;
				padding-left: 2.4rem;
			}
		}
	}


	// HR
	hr {
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid palette(primary-border-colour);
		margin: $vertical-margins 0;
		padding: 0;

		&.dotted {
			border-top: 3px dotted palette(primary-border-colour);
		}
	}


	// List styles
	ul,ol {
		font-family: $secondary-font-stack-light;
		font-size: 1.3rem;
		color: $grey-text;
		margin: 0 0 $vertical-margins 0;
	}

	// Nested list styles
	ul,ol {
		ul,ol {
			margin: 0;
		}
	}


	// Tables
	table {
		width: 100%;
		border: none;

		th{
			font-family: $primary-font-stack;
			font-weight: normal;
			font-size: 1rem;
			line-height: 1.6rem;
			color: $yellow;
			background: $black;
			padding-left: 2rem;
			padding-top: 1rem;
			padding-bottom: 1rem;
		}

		tr{
			td:last-child{
				padding-right: 2rem;
			}

			td:last-child{
				padding-right: 2rem;
			}
		}

		td{
			font-weight: lighter;
			font-size: 1rem;
			color: $grey-text;
			line-height: 1.6rem;
			padding-left: 2rem;
			padding-top: 1rem;
			padding-bottom: 1rem;
		}

		tr:nth-child(even) td{
			background-color: #fafafa;
			border-top: 1px solid #dddddd;
			border-bottom: 1px solid #dddddd;
		}

		tr.mandatory td{
			background-color: #cccccc;
		}

	}

	th {
		text-align: left;
	}
