.journals {
	@extend .content-container;
	padding-top: 30px;
	padding-bottom: 30px;

	@include mq(md) {
		padding-top: 60px;
		padding-bottom: 60px;		
	}
}
	.journals__row {
		@extned .row;
		@include flex-container();
	}

	.journals__item {
		@include grid-col(1,2);
		text-align: center;	
		margin-bottom: 15px;

		@include mq(xs) {
			@include grid-col(1,3);
		}
		@include mq(sm) {
			@include grid-col(1,4);
		}
		@include mq(md) {
			@include grid-col(1,5);
		}
	}

	.journals__item--main {
		@extend .journals__item;
		@include grid-col(1,1);
		
		@include mq(xs) {
			@include grid-col(1,3);
		}
		@include mq(sm) {
			@include grid-col(1,3);
		}
		@include mq(md) {
			@include grid-col(1,3);
		}
	}

	.journals__covers img {
		width: 75%;
		height: auto;
	}

	.journals__title {
		max-width: 280px;
		margin: 0 auto;

		a {
			text-decoration: none;
			font-family: font(condensed);
			font-size: font(size-xxs);
			line-height: 1.2;		

			@include mq(sm) {
				font-size: font(size-xs);
			}

			@include mq(md) { 
				font-size: font(size-sm);
			}
		}
	}

	.journals__year {
		font-size: font(size-xxs);

		@include mq(sm) {
			font-size: font(size-xs);
		}

		@include mq(md) { 
			font-size: font(size-sm);
		}
	}

