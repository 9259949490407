.cms-content {
	img {
		max-width: 100%;
		height: auto !important;
	}

	// Force break long email address on mobile
	@include mq(767px, max) {
		a[href^="javascript:void(location.href='mailto:'"],
		a[href^="mailto:"] {
			overflow-wrap: break-word;
			word-wrap: break-word;
			-ms-word-break: break-all;
			/* This is the dangerous one in WebKit, as it breaks things wherever */
			word-break: break-all;
			/* Instead use this non-standard one: */
			word-break: break-word;
		}
	}

	@include mq(767px, max) {
		table {
			width: 100% !important;
			height: auto;
		}

		/* Zebra striping */
		// tr:nth-of-type(odd) {
		// 	background: #eee;

		// 	td {
		// 		border-bottom: 1px solid #fff;
		// 	}
		// }

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr {
			display: block;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		tr {
		//	border: 1px solid #ccc;
		}

		td {
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			position: relative;
		}
	}
}

.content__page-not-found--margin{
	margin: 100px 0 100px 40px;
}

.footer__page-not-found{
	position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 251px;
  margin-bottom: 0px;
}
