$menu-font-family: "icon-font";
$menu-value: "\e000";
$menu: "icon-font" "\e000";

@mixin icon-font-family($char) {
  font-family: nth($char, 1);
}

@mixin icon-font() {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

@mixin icon-content($char) {
  content: nth($char, 2);
}

@mixin icon($char) {
  @include icon-font-family($char);
  @include icon-font();

  &:before {
    @include icon-content($char);
  }
}

@font-face {
  font-family: "icon-font";
  src:url("/httpdocs/fonts/icon-font.eot");
  src:url("/httpdocs/fonts/icon-font.eot?#iefix") format("embedded-opentype"),
    url("/httpdocs/fonts/icon-font.woff") format("woff"),
    url("/httpdocs/fonts/icon-font.ttf") format("truetype"),
    url("/httpdocs/fonts/icon-font.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
