.bottom-cta {
	position: relative;
	margin-bottom: -30px;
	
	@include mq(sm) {
		height: 440px;
	}

	@include mq(md) {
		margin-bottom: -60px;		
	}	
}

	.bottom-cta__call {		
		background-color: $black;
		text-align: center;
		position: relative;

		&:before {
			@include translate(-50%, 100%);
			content: ' ';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 30px 30px 0 30px;
			border-color: #000000 transparent transparent transparent;
			position: absolute;
			bottom: 0;
			left: 50%;

			@include mq(sm) {
				display: none;
			}
		}

		> a {
			text-decoration: none;
			font-size: font(size-xxl);
			font-family: font(condensed);
			text-transform: uppercase;
			color: $yellow;
		}

		@include mq(sm) {
			@include translate(-100%, -50%);
			border-radius: 50%;
			width: 370px;
			height: 370px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -25px;

			&:after {
				@include translate(0, -50%);
				top: 50%;
				content: ' ';
				display: block;
				position: absolute;
				right: -25px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 30px 0 30px 30px;
				border-color: transparent transparent transparent #000000;
			}

			> a {
				@include translate(0, -50%);
				position: absolute;
				text-decoration: none;
				top: 50%;
				left: 0;
				display: block;
				width: 100%;
				font-size: font(size-xxxl);
				font-family: font(condensed);
				text-transform: uppercase;
				color: $yellow;
				line-height: 1;

				@include mq(sm) {					
					font-size: font(size-xxxxl);
				}
			}
		}	
	}

	.bottom-cta__links {
		@extend %list-reset;
		padding-top: 50px;

		@include mq(sm) {
			@include translate(0, -50%);
			padding-top: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: 35px;
		}
	}

	.bottom-cta__link-item {
		@extend %list-item-reset;
		padding: 5px 0;

		@include mq(sm) {
			text-align: left;
			padding: 13px 0;
		}

		a {
			text-decoration: none;
			color: $black;
			text-transform: uppercase;
			font-size: font(size-sm);
			font-family: font(condensed);		
		}
	}