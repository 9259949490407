.lw-widget{
  top: inherit;
  height: auto;


  @include mq(sm){
    bottom: inherit;
    top: 23rem;
  }

  @include mq(md){
    top: 18rem;
  }

  @include mq(lg){
    top: 28rem;
  }

  @include mq(xlg){
    top: 36rem;
  }
}


.lw-container{
  max-width: 17rem;
}

.lw-item{

  &.lw-visible{
    opacity: 1;
  }

  &__transparent{
    background-color: rgba(0,0,0,0.8);
  }

  .lw{

    &-title{
      font-family: $primary-font-stack;
      font-weight: 400;
      color: $yellow;
      text-transform: uppercase;
      font-size: 1.5rem;
      opacity: 1;
    }

    &-close{
      font-size: 1.3rem;
      opacity: 1;
      font-weight: 100;
      padding: .2rem .7rem;
    }

    &-content{
      font-family: $primary-font-stack;
      text-transform: uppercase;
      font-weight: 100;
      font-size: .8rem;
      color: $white;
      opacity: 1;

    }

    &-button{
      a{

        &:link, &:visited, &:active{
          display: inline-block;
          font-family: $primary-font-stack;
          font-weight: 400;
          text-transform: uppercase;
          font-size: .8rem;
          color: $yellow;
          margin-top: .5rem;
        }

        &:hover{
          color: $faded-white;
        }
      }
    }
  }
}
