.registrar-portal-message {
  margin-bottom: 3.5rem;
}

.extra-years-dropdown {
  position: relative;
  cursor: pointer;
  height: 41px;
  line-height: 41px;
  display: inline-block;
  width: 100%;
  text-align: center;

  @include mq(sm)
  {
    width: auto;
    text-align: left;
    padding-left: 0.5rem;
    padding-right: 1.5rem;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0.5rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath d='M15 18.7l6-6-1.41-1.4L15 15.88l-4.59-4.58L9 12.7z'/%3E%3C/svg%3E");
    height: 1.5rem;
    width: 1.5rem;
  }

  &:hover {
    .extra-year-dropdown-items {
      transform: scaleY(1);
    }
  }

  .extra-years-dropdown-checkbox {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    opacity: 0;

    &:checked + .extra-year-dropdown-items {
      transform: scaleY(1);
    }
  }

  .extra-year-dropdown-items {
    top: 0;
    left: 0;
    z-index: 1;

    @include mq(sm)
    {
      position: absolute;
      max-height: 0;
      transform: scaleY(0);
      margin-top: -0.25rem;
      padding-top: 2.9rem;
      min-width: 9rem;
    }

    .extra-year-dropdown-items-container {
      min-width: 10.3rem;

      @include mq(sm)
      {
        padding-top: 0.25rem;
        background-color: $white;
        border: 2px solid $black;
      }
    }

    a {
      display: block;
      text-decoration: none;
      padding-left: 0.5rem;

      @include mq(sm)
      {
        margin-top: -0.25rem;
      }
    }
  }
}

.extra-years-dropdown.active {
  background-color: $black;
  color: $white;

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg fill='%23ffffff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath d='M15 18.7l6-6-1.41-1.4L15 15.88l-4.59-4.58L9 12.7z'/%3E%3C/svg%3E");
  }

  a.active {
    background-color: $black;
    color: $white;
  }
}

.enable-extra-years-filter {
  padding-bottom: 0.25rem;

  @include mq(sm) {
    display: grid;
    grid: repeat(2, 36px) / auto-flow 170px;
  }

  @include mq(md)
  {
    display: block;
    padding-bottom: 0;
    transform: translate(-33%, 0);
    left: 40%;
  }
}

.registrar-name-title {
  h2 {
    text-align: center;
  }
}
