$mobile-header-height-home: 60px;
$header-height-home: 90px;

.heading__other-pages{
	overflow: visible;
	width: 100%;
	background: none;
	display: inline-flex;
	height: auto;
	top: 0;
	z-index: 3;
	background: #000000;

	.heading__background--black{
		width: 100%;
		display: flex;
		background: black;
		height: 7rem;
		z-index: 1;

		.heading__logo{
			flex: 1 1 28%;
			padding: 2rem 0 0 1rem;

			img{
				margin: 0;
			}
		}

		.heading__navigation{
			flex: 1 1 60%;
			margin-top: 1rem;
		}

		.navigation__search-mobile-nav{
			margin: 2.7rem 0 0 1.5rem;
			background: url('/img/icons/search-mobile-white.png') no-repeat center center;
			background-size: contain;
			display: inline-block;
			height: 1.6rem;
			width: 1.6rem;
		}

		.navigation__trigger-mobile-nav{
			width: 1.5rem;
			margin: 1.7rem 1.25rem 0 0;
		}



		@include mq(sm){
			.navigation__search-mobile-nav{
				margin: 2.7rem 0 0 4rem;
			}

		}

		@include mq(md){

			height: 8.5rem;
			padding-top: 1rem;

			.heading__logo{
				padding: .5rem 0 0 1rem;
				flex: 1 1 28%;

				img{
					width: 10rem;
				}
			}

			.heading__navigation{
				margin-top: 1rem;
				flex: 1 1 60%;
			}

			.heading__other{
				flex: 1 0 28%;
			}

			.navigation__search-mobile-nav{
				display: none;
			}
		}

		@include mq(lg){

			.heading__logo{
				flex: 1 1 28%;
				padding: 0.6rem 0 0 4rem;

				img{
					width: 18.6rem;
					padding-top: 0;
				}
			}

			.heading__navigation{
				margin-top: 1.8rem;
			}

			.heading__other{
				margin-top: 0.4rem;
				flex: 1 0 28%;
			}
		}
	}

	.heading__other{

		.header__buttons{
			margin-top: 1.5rem;

		}//header__buttons

	} //heading__other

}

//End heading__other-pages

.heading__other-pages--top__margin{
	margin-top: 0rem;

	@include mq(sm){
		// margin-top: 6rem;
		min-height: 70rem;
	}


}


.heading {
	//@extend .container;
	position: absolute;
	overflow: hidden;
	width: 100%;
	background: none;
	display: inline-flex;
	height: 36rem;
	top: 0;
	z-index: 1;

	.heading__background--black{
		width: 100%;
		display: flex;
		height: 8.5rem;
	}
	//heading__background--black
	//
	.navigation__search-mobile-nav{
		margin: 2.1rem 0 0 1rem;
		background: url('/img/icons/search-mobile-white.png') no-repeat center center;
		background-size: contain;
		display: inline-block;
		height: 1.6rem;
		width: 1.6rem;
	}

	@include mq(xs){
		.navigation__search-mobile-nav{
			width: 2.5rem;
		}
	}

	@include mq(sm){
		height: auto;

		.navigation__search-mobile-nav{
			margin: 2.1rem 0 0 3rem;
		}
	}

	@include mq(md){
		height: auto;

		.navigation__search-mobile-nav{
			display: none;
		}
	}

	@include mq(lg){
		height: auto;
	}

	@include mq(xlg){
		height: auto;
	}
}

.heading__logo{
	width: 100%;
	text-align: left;
	z-index: 9;
	height: fit-content;

	img{
		margin: 1.3rem 0 0 1.5rem;
		width: 11rem;
	}

	@include mq(sm){
		flex: 1 1 28%;
		padding: 1.5rem 0 0 1.5rem;

		img{
			width: 12rem;
			margin: 0;
		}
	}

	@include mq(md){

		flex: 1 1 28%;
		padding: .5rem 0 0 2rem;

		img{
			width: 12rem;
    	padding-top: .9rem;
		}

	}

	@include mq(lg){

		flex: 1 1 28%;
		padding: 1.5rem 0 0 3rem;

		img{
			width: 15rem;
			padding-top: 0;
		}

	}

	@include mq(xlg){
		flex: 1 1 28%;
		padding: 1.7rem 0 0 4rem;

		img{
			width: 18.6rem;
			padding-top: 0;
		}
	}

}

.heading__other{

	flex: 1 0 28%;
	text-align: right;
	padding: 0 1rem 0 0;
	display: none;

	.header__buttons li a{
		font-weight: 200;
	}

	.header__buttons{

		.is-primary-button{

			a{
				margin-right: .4rem;
				color: #000;

				&:active{
					color: #000;
				}

				&:hover{
					color: #000;
				}

				&:visited{
					color: #000;
				}
			}
		}

	}

	@include mq(xs){
		display: none;

	}

	@include mq(sm){
		display: none;

		flex: 1 0 28%;

		.search{
			width: .5rem !important;
	    	min-height: .5rem !important;
	    	margin-left: .5rem !important;
		}

		.header__buttons{
			margin-top: 1.5rem;
			margin-left: 0;
		}

		.header__buttons li a {
			width:  4rem;
			font-size: 0.6rem;
			min-height: 1.4rem;
			padding: .5rem;

		}

		.heading__other{
			padding: 0 1.5rem 0 0;
		}

	}

	@include mq(md){
		display: block;
		flex: 1 0 28%;

		.search{
			width: 1.5rem !important;
	    height: 1.5rem !important;
	    margin-left: 1rem !important;
		}

		.header__buttons{
			margin-top: 1.65rem;
			padding-left: 0;
		}

		.header__buttons li a {
			min-height: 0;
			font-size: .8rem;
			padding: .5rem 1rem;
			font-weight: normal;
			height: auto;
			width: auto;
		}

		.heading__other{
			flex: 1 0 28%;
			padding: 0 1.5rem 0 0;
		}


	}

	@include mq(lg){
		display: block;
		flex: 1 0 28%;

		.search{
			width: 1.5rem !important;
	    min-height: 1.4rem !important;
	    margin-left: 1rem !important;
		}

		.header__buttons{
			margin-top: 2.4rem;
		}

		.header__buttons li a {
			width:  auto;
			min-height: 1.8rem;
			font-size: 1rem;
			padding: .8rem 1.5rem;
		}


	}

	@include mq(xlg){

		.search{
			width: 1.5rem !important;
	    min-height: 1.8rem !important;
	    margin-left: 1rem !important;
			background-repeat: no-repeat !important;
		}

		.heading__button--search{
			margin-top: .2rem;
			margin-left: 1rem;
		}

		.header__buttons{
			margin-top: 3.2rem;
		}

		.header__buttons li a {
			width: auto;
			min-height: 2.3rem;
			font-size: 1rem;
			padding: .6rem 1.5rem;
			font-weight: 300;
		}

	}
}

.heading__button-spacing{
	margin-left: 0;
}

.heading__button-small{
	z-index: 9;
}

.is-marginless{
	margin: 0 !important;
}

.is-above-other-element{
	z-index: 10;
}

.header__megamenu {
	min-height: 500px;
	opacity: 0;
	transform: translate(0,-100%);
	transition: .3s ease-out;
	transition-property: opacity;
	background-color: #252525;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	padding-top: 120px;
	padding-bottom: 40px;
	border-bottom: 2px solid rgb(214, 177, 44);
	border-bottom: 2px solid rgba(255, 205, 3, .7);
	background-clip: padding-box;
	z-index: 5;
	&--search {
		padding-top: 209px;
		padding-bottom: 210px;
		.container {
			max-width: 1020px;
			display: block;
		}
		form {
			width: 100%;
		}
		::placeholder {
			color: #666;
		}
		input {
			display: block;
			width: 100%;
			border-radius: 0;
			-webkit-appearance: none;
			padding: 20px 10px;
			color: #fff;
			font-family: $primary-font-stack;
			font-weight: 300;
			font-size: 30px;
			background-color: transparent;
			border: 0;
			border-bottom: 1px solid #666;
		}
	}
	h3 {
		color: #fff;
		font-family: $primary-font-stack;
		margin-bottom: 20px;
	}
	li {
		display: block;
		a {
			text-transform: none;
			display: inline;
			&:hover {
				color: $yellow;
			}
		}
		&:not(:last-child) {
			margin-right: 0;
			margin-bottom: 13px;
		}
	}
	.container {
		max-width: 1024px;
		display: flex;
	}
}
.header__megamenu-col {
	flex-basis: 33.333%;
	&--noh3 {
		padding-top: 50px;
	}
}

.header__buttons {
	margin-top: 44px;
	margin-left: auto;
	font-size: 0;
	li {
		display: inline-block;
		vertical-align: middle;

		a {

			height: 17px;
			width: 17px;

			&.search {
				background: url('/img/svg/icon-search-white.svg');
				background-size: contain;
    		background-repeat: no-repeat;
			}
		}

		&:not(:last-child) {
			margin-right: 43px;
		}
		&:hover {
			.header__megamenu {
				opacity: 1;
				visibility: visible;
				transform: translate(0,0);
			}
		}
	}
	a {
		display: inline-block;
		vertical-align: middle;
		color: #fff;
		font-size: 14px;
		position: relative;
		z-index: 12;
	}


}
