// ----------------------------------------------------------
// =Header
// ----------------------------------------------------------
$mobile-header-height: 60px;
$header-height: 150px;

.header {
	@include transition(background-color ease-in-out 300ms);
	padding: 0;
	background-color: $white;
	top: 0;
	width: 100%;
	max-width: 1500px;
	z-index: 200;
	height: $mobile-header-height;
	margin: 0 auto;
	position: relative;

	@include mq(md){
		height: $header-height;
	}

	.mm-opened & {
		display: none;
	}
}

.header--home {
	@extend .header;
	background-color: transparent;
}

.header__inner {
	margin: 0 auto;
	width: 100%;
	max-width: map-get($guards, xlg);
}

.header__logo {
	display: block;
	margin: 0;
	padding: 10px 0 0 5px;
	max-width: 210px;
	float: left;

	@include mq(xs){
		max-width: 320px;
		padding-top: 2px;
	}

	@include mq(md){
		padding: 46px 0 0 10px;
		max-width: 420px;
	}
}
.header__logo--home {
	@extend .header__logo;
	position: relative;

	@include mq(md){
		padding: 10px 0 0 10px;
		max-width: 43%;
		width: 550px;
	}

	&:before {
		content: ' ';
		background-color: $white;
		display: block;
		position: absolute;
		top: 0;
		left: -15px;
		right: -25px;
		bottom: -10px;
		width: auto;
		height: auto;
		opacity: 0.4;
		z-index: 0;

		@include mq(md){
			bottom: -15px;
		}
	}
}

	.header__logo-link {
		display: block;
		padding: 0;
		margin: 0;
		position: relative;
	}

	.header__logo-image {
		display: block;
		max-width: 100%;
		height: auto;
		position: relative;
		z-index: 1;
	}

.header__search {
	@extend %hide-on-print;
	@include transition(width ease-in-out 300ms);
	@include placeholder {
		color: $dark-grey;
	}
	display: block;
	margin: 0;
	padding: 0;
	width: 70px;
	height: $mobile-header-height;
	//background-color: $almost-white;
	position: absolute;
	top: 0;
	right: 45px;

	.header--home & {
		background-color: transparent;
	}

	@include mq(xs){
		right: $mobile-header-height;
	}

	@include mq(md){
		height: 80px;
		right: 0;
		top: 70px;
	}

	@include mq(lg){
		width: 80px;
	}

	@include mq(1530px){
		right: 50%;
		margin-right: -750px;
		max-width: 720px;
	}

	&.active {
		width: calc(100% - #{$mobile-header-height} + 15px);

		@include mq(xs){
			width: calc(100% - #{$mobile-header-height});
		}

		@include mq(sm){
			width: 55%;
		}
		@include mq(1500px){
			width: 48%;
		}
	}

	&:hover {
		@include mq(sm){
			width: 55%;
		}
		@include mq(1500px){
			width: 48%;
		}
	}
}

	.header__search-trigger {
		position: absolute;
		cursor: pointer;
		right: 0;
		top: 0;
		width: 45px;
		height: $mobile-header-height;
		background: url("#{$icons}icon-search.png") no-repeat center center $white;
		z-index: 2;

		.header--home & {
			background-color: transparent;
		}

		@include mq(xs){
			width: $mobile-header-height;
		}

		@include mq(md){
			height: 80px;
			width: 70px;
		}
		@include mq(lg){
			width: 80px;
		}
		@include mq(1530px){
			width: 45px;
		}
	}

	.header__search-submit {
		@extend .header__search-trigger;
		text-indent: -9999px;
		padding: 0;

		&:hover {
			background: url("#{$icons}icon-search.png") no-repeat center center $almost-white;;
		}
	}

	.header__search-container {
		@include transition(opacity ease-in-out 300ms);
		position: absolute;
		right: 0;
		top: 0;
		height: $mobile-header-height;
		//background-color: $almost-white;
		overflow: hidden;
		z-index: 1;
		width: 100%;
		opacity: 0;

		.header--home & {
			background-color: transparent;
		}

		@include mq(md){
			height: 80px;
		}

		.header__search.active & {
			background-color: $almost-white;
			z-index: 3;
			opacity: 1;
		}

		.header__search:hover & {
			@include mq(md){
				background-color: $almost-white;
				z-index: 3;
				opacity: 1;
			}
		}
	}

	.header__search-form {
		z-index: 1;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: $mobile-header-height;
		//background-color: $almost-white;

		.header--home & {
			background-color: transparent;
		}

		@include mq(md){
			height: 80px;
		}
	}

	.header__search-input {
		background-color: transparent;
		outline: none;
		border: none;
		height: $mobile-header-height;
		padding-left: 20px;
		padding-right: 45px;
		font-family: font(light);
		font-size: font(size-xs);
		color: $black;
		width: 100%;

		@include mq(md){
			height: 80px;
			padding-right: 70px;
		}

		@include mq(lg){
			padding-right: 80px;
		}

		@include mq(1500px){
			padding-right: 45px;
		}
	}

	.header__search-label {
		@include transition(all ease-in-out 300ms);
		position: absolute;
		left: 20px;
		font-family: font(light);
		font-size: font(size-xs);
		top: 18px;
		color: $dark-grey;

		@include mq(md){
			top: 28px;
		}

		.header__search-input:focus ~ &,
		.header__search-input.has-input ~ & {
			top: 4px;
			font-size: font(size-xxxxs);
			color: $black;

			@include mq(md){
				top: 10px;
			}
		}
	}

	//Hide slick arrow
	// .slick-next{
	// 	display: none !important;
	// }
.image-hero-wrapper {
	position: relative;
	min-height: 43rem;

	.header-acknowledgement-notice__wrapper {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: auto;
		margin-bottom: 0;
		padding: 0 2rem;

		p {
			color: #000;
			font-size: 12.5px;
			max-width: 74rem;
			margin: 0 auto;
			margin-bottom: 1.5rem;
			text-align: center;
			padding: 0.75rem;
			position: relative;
			font-family: $primary-font-stack;
			font-style: italic;

			@include mq(lg){
				margin-bottom: 2rem;
			}

			&::before {
				content: "";
				opacity: 0.88;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: #fff;
				z-index: -1;
				text-shadow: #000 2px 5px;
			}
		}
	}
}
