.accordion {
	margin-bottom: 24px;
}

	.accordion__heading {
		border-bottom: 3px solid $white;
		color: $grey;
		cursor: pointer;
		font-size: font(size-sm);
		padding: 20px 30px;
		position: relative;
		background-color: $almost-white;

		&:hover {
			color: $yellow;
		}
	}

	.accordion__body {
		display: none;
		color: $grey;
		margin: 0 0 24px 0;
		padding: 20px 30px;
		overflow: hidden;

		&.expanded {
			margin-bottom: 0;
		}
	}

.ui-accordion{

	&-header{
		font-family: $primary-font-stack;
		font-weight: normal;
		text-transform: capitalize;
		background-color: #e1e1e1;
		padding: 1rem 2rem;
		margin: .5rem 0;
		outline: none;

		&::after{
			content: "▼";
			position: relative;
			float: right;
			color: $white;
			font-size: 1.3rem;
		}

		&-active{
			background-color: $black;
			color: $yellow;
			margin: 0;

			&::after{
				content: "▲";
				position: relative;
				float: right;
				color: $yellow;
				font-size: 1.3rem;
			}
		}
	}

	&-content{

		a:link,a:visited,a:active{
			font-family: $secondary-font-stack-light;
			font-size: 1.3rem;
			color: $blue-link;
			text-decoration: none;
		}

		a:hover{
			color: $blue-link;
			text-decoration: underline;
		}

		&-active{
			font-family: $primary-font-stack;
			background-color: #f3f3f3;

			span{
				display: inline-block;
				padding: 2rem;
			}
		}
	}
}
